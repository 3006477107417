import AppState from '@/states/app'
import config from '../../config'
import { requests } from './BaseService'

export type UserType = 'all' | 'assign' | 'user'
export type ToTypeEmail = UserType | 'custom_email'

export interface IStoredFile {
  name: string
  url: string
  size?: number
}

export interface IEmail {
  from_name?: string
  from_email?: string
  subject: string
  template_id?: string
  templatesource?: 'other' | 'email-builder'
  to?: string | string[]
  userType?: ToTypeEmail
  selectedUser?: string[]
  html?: string
  attachments?: IStoredFile[]
  previewUrl?: string
  createdAt?: string
  updatedAt?: string
  testEmails?: string[]
}

export interface ICreateEmailTemplateOptions {
  locationId: string
  updatedBy: string
  type: string
  name?: string
  parentId?: string
  templateDataUrl?: string
  isPlainText?: boolean
}

export default class EmailService {
  email: IEmail | undefined
  senderAddress: IEmail | undefined

  static get endpoint() {
    return `workflow/${AppState.locationId}/email`
  }

  constructor(email: IEmail, senderAddress?: Partial<IEmail>) {
    this.email = email
    if (senderAddress) {
      this.senderAddress = senderAddress as IEmail
    }
  }

  async sendTestEmail() {
    try {
      await requests().post(`${EmailService.endpoint}/send-test-email`, {
        location_id: AppState.locationId,
        user_id: AppState.user?.id,
        email: this.email,
        senderAddress: {
          from_name: this.senderAddress?.from_name,
          from_email: this.senderAddress?.from_email,
        },
      })
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

export const EmailBuilderService = {
  createEmailTemplate: (options: ICreateEmailTemplateOptions) =>
    requests().post(`${config.calendarApiUrl}/emails/builder`, {
      ...options,
    }),
}
