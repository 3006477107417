import { EmailNotifications } from '@/types/notification'
export interface LocationConfigurations {
  kind: string

  location: string

  position: number
  meetingId?: string

  zoomOauthId?: string

  alias?: string

  msTeamsOauthId?: string
}
export const defaultNotificationStatus = [
  {
    type: 'booked',
    status: false,
  },
  {
    type: 'confirmation',
    status: true,
  },
  {
    type: 'cancellation',
    status: false,
  },
  {
    type: 'reminder',
    status: false,
  },
  {
    type: 'followup',
    status: false,
  },
  {
    type: 'reschedule',
    status: false,
  },
] as EmailNotifications
export interface TeamMember {
  isZoomAdded?: string
  selected?: boolean
  meetingLocation?: string

  locationConfigurations: LocationConfigurations[]
  priority: number
  userId: string
  zoomOauthId?: string
  userName?: string
  isPrimary?: boolean
  useMeetingForCollective: boolean
  isMsTeamsAdded?: string
  msTeamsOauthId?: string
}
export interface PageOrder {
  position: number
  kind: string
}
export interface PrimarySettings {
  primaryColor: string
  backgroundColor: string
  buttonText: string
  showCalendarTitle: boolean
  showCalendarDescription: boolean
  showCalendarDetails: boolean
}

export interface WidgetConfig {
  primarySettings?: PrimarySettings
  default?: boolean
  pageOrder?: PageOrder[]
}

export interface ReschedulePreference {
  expiryTime: number
  expiryTimeUnit: string
}

export interface CancellationPreference {
  expiryTime: number
  expiryTimeUnit: string
}

interface LookBusyConfig {
  enabled: boolean
  lookBusyPercentage: number
}

enum NotificationType {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum TeamMeetingOptions { //isZoomAdded
  ZOOM = 'true',
  CUSTOM = 'false',
  GMEET = 'meet',
  ADDRESS = 'address',
  PHONE = 'phone',
}

export interface Price {
  currencyCode: string
  priceMicros: number
}

export interface HomeServiceData {
  /**
   * @category_type
   * The high level category to which this home service belongs to. E.g. plumber, electrician etc.
   */
  categoryType: string

  /**
   * @job_type
   * The job type under the category to which the given home service belongs to. E.g. unclog_drain, install_faucet are the job types under plumber category.
   */
  jobType: string
}

export enum LogoShape {
  SQUARE = 'square',
  ROUND = 'round',
}
export interface WidgetLogo {
  url?: string
  shape?: LogoShape
}

export enum SyncOption {
  TWOWAY = 'twoway',
  ONEWAY = 'oneway',
  DISABLE_TRIGGER = 'disable_trigger',
  SMART = 'smart',
}

export enum GuestType {
  COUNT_ONLY = 'count_only',
  COLLECT_DETAIL = 'collect_detail',
}

export enum DepositType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

export enum RecurringAppointmentsPaymentType {
  FirstAppointmentOnly = 'firstAppointmentOnly',
  AllAppointments = 'allAppointments',
}

export enum EventType {
  RoundRobin_OptimizeForAvailability = 'RoundRobin_OptimizeForAvailability',
  RoundRobin_OptimizeForEqualDistribution = 'RoundRobin_OptimizeForEqualDistribution',
  Collective = 'Collective',
  Group = 'Group',
}

export interface IGoogleCalendar {
  id: string
  name: string
  accessRole: string
  watchId: string
  expiration: number
  userId: string
  oauthId?: string
  lastSync?: string
  error?: number
  errorMessage?: string
}

export interface ILinkedCalendars {
  google?: IGoogleCalendar
}

export interface ICalendarNotification {
  type: NotificationType
  shouldSendToContact: boolean
  shouldSendToGuest: boolean
  shouldSendToUser: boolean
  shouldSendToSelectedUsers: boolean
  selectedUsers: string
  templateId: string
}

export interface IRRule {
  freq: RecurringFrequency
  count: number
  interval?: number
  weekDays?: WeekDay[]
  monthDays?: number[]
}

export interface Stripe {
  amount: number
  currency: string
  chargeDescription?: string
  deposit: number
  depositType: DepositType
}

export interface IRecurring extends IRRule {
  bookingOption: RecurringBookingOption
  bookingOverlapDefaultStatus?: RecurringOverlapStatusOption
}

export enum FormSubmitType {
  RedirectURL = 'RedirectURL',
  ThankYouMessage = 'ThankYouMessage',
}

export enum RecurringFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum RecurringBookingOption {
  SKIP = 'skip',
  CONTINUE = 'continue',
  BOOK_NEXT = 'book_next',
}

export enum RecurringOverlapStatusOption {
  CONFIRMED = 'confirmed',
  UNCONFIRMED = 'unconfirmed',
}

export enum WeekDay {
  SUNDAY = 'SU',
  MONDAY = 'MO',
  TUESDAY = 'TU',
  WEDNESDAY = 'WE',
  THURSDAY = 'TH',
  FRIDAY = 'FR',
  SATURDAY = 'SA',
}

interface Recurring {
  freq: RecurringFrequency
  count: number
  interval?: number
  weekDays?: WeekDay[]
  monthDays?: number[]
  bookingOption: RecurringBookingOption
  bookingOverlapDefaultStatus?: RecurringOverlapStatusOption
}

export enum CalendarType {
  ROUND_ROBIN = 'round_robin',
  EVENT = 'event',
  CLASS_BOOKING = 'class_booking',
  COLLECTIVE = 'collective',
  SERVICE = 'service_booking',
  PERSONAL = 'personal',
}

export enum WidgetType {
  DEFAULT = 'default',
  CLASSIC = 'classic',
}

export default class Calendar {
  public static NotesText_PhoneEmail =
    'Phone:- {{contact.phone}}\nEmail:- {{contact.email}}\n'
  public static NotesText_Title_RescheduleOrCancellation =
    'Need to make a change to this event?'
  public static NotesText_Reschedule = 'Reschedule:-\n{{reschedule_link}}'

  public static NotesText_Cancellation = 'Cancel:-\n{{cancellation_link}}'
  private data: any = {}

  constructor(data: any) {
    this.data = data
  }

  set id(id: string) {
    this.data.id = id
  }

  get id(): string {
    return this.data.id
  }

  set name(name: string) {
    this.data.name = name
  }

  get name(): string {
    return this.data.name
  }

  get providerId(): string {
    return this.data.providerId
  }

  set providerId(providerId: string) {
    this.data.providerId = providerId
  }

  get calendarType(): CalendarType {
    return this.data.calendarType
  }

  set calendarType(value: CalendarType) {
    this.data.calendarType = value
  }

  get widgetType(): WidgetType {
    return this.data.widgetType
  }

  set widgetType(value: WidgetType) {
    this.data.widgetType = value
  }

  get showSeatsPerSlot(): boolean {
    return this.data.showSeatsPerSlot
  }

  set showSeatsPerSlot(_showSeatsPerSlot: boolean) {
    this.data.showSeatsPerSlot = _showSeatsPerSlot
  }

  get isLivePaymentMode(): boolean {
    return this.data.isLivePaymentMode
  }

  set isLivePaymentMode(mode: boolean) {
    this.data.isLivePaymentMode = mode
  }

  get stripe(): Stripe {
    return this.data.stripe
  }

  set stripe(stripe: Stripe) {
    this.data.stripe = stripe
  }

  get widgetSlug(): string {
    return this.data.widgetSlug
  }

  set widgetSlug(value: string) {
    this.data.widgetSlug = value
  }

  get eventTitle(): string {
    return this.data.eventTitle
  }

  set eventTitle(value: string) {
    this.data.eventTitle = value
  }

  get linkedCalendars(): ILinkedCalendars {
    if (!this.data.linkedCalendars) {
      this.data.linkedCalendars = {}
    }
    if (!this.data.linkedCalendars.google) {
      this.data.linkedCalendars.google = {}
    }
    return this.data.linkedCalendars
  }

  set linkedCalendars(linkedCalendars: ILinkedCalendars) {
    this.data.linkedCalendars = linkedCalendars
  }

  get syncOption(): SyncOption {
    return this.data.syncOption || SyncOption.ONEWAY
  }

  set syncOption(syncOption: SyncOption) {
    this.data.syncOption = syncOption
  }

  get meetingLocation(): string {
    return this.data.meetingLocation
  }

  set meetingLocation(value: string) {
    this.data.meetingLocation = value
  }

  get locationConfigurations() {
    return this.data.locationConfigurations
  }

  set locationConfigurations(value: LocationConfigurations[]) {
    this.data.locationConfigurations = value
  }

  get eventColor(): string {
    return this.data.eventColor
  }

  set eventColor(value: string) {
    this.data.eventColor = value
  }

  get version(): number {
    return this.data.version
  }

  set version(value: number) {
    this.data.version = value
  }

  get groupName(): string {
    return this.data.providerId
  }

  set groupName(groupName: string) {
    this.data.groupName = groupName
  }

  get isV3Calendar(): boolean {
    return (
      this.data.calendarType === CalendarType.ROUND_ROBIN ||
      this.data.calendarType === CalendarType.CLASS_BOOKING ||
      this.data.calendarType === CalendarType.COLLECTIVE ||
      this.data.calendarType === CalendarType.PERSONAL
    )
  }

  get isEventCalendar() {
    return this.calendarType === CalendarType.EVENT
  }

  get isRoundRobinCalendar() {
    return this.calendarType === CalendarType.ROUND_ROBIN
  }

  get isClassBookingCalendar() {
    return this.calendarType === CalendarType.CLASS_BOOKING
  }

  get isCollectiveCalendar() {
    return this.calendarType === CalendarType.COLLECTIVE
  }

  get isServiceCalendar() {
    return this.calendarType === CalendarType.SERVICE
  }

  get isRecurringCalendar(): boolean {
    return this.data.enableRecurring
  }

  get teamMembers(): TeamMember[] {
    return this.data.teamMembers
  }

  set teamMembers(teamMembers: TeamMember[]) {
    this.data.teamMembers = teamMembers
  }

  get openHours(): any {
    return this.data.openHours
  }

  set openHours(openHours: any) {
    this.data.openHours = openHours
  }

  get availabilities(): any {
    return this.data.availabilities || []
  }

  set availabilities(availabilities: any) {
    this.data.availabilities = availabilities
  }

  get availabilityType(): number {
    return this.data.availabilityType
  }

  set availabilityType(availabilityType: number) {
    this.data.availabilityType = availabilityType
  }

  get slotDuration(): number {
    return Number(this.data.slotDuration)
  }
  set slotDuration(slotDuration: number) {
    this.data.slotDuration = slotDuration
  }

  get slotInterval(): number {
    return Number(this.data.slotInterval)
  }
  set slotInterval(slotInterval: number) {
    this.data.slotInterval = slotInterval
  }

  get slotIntervalUnit(): string {
    return this.data.slotIntervalUnit
  }
  set slotIntervalUnit(slotIntervalUnit: string) {
    this.data.slotIntervalUnit = slotIntervalUnit
  }

  get slotBuffer(): number {
    return this.data.slotBuffer
  }

  set slotBuffer(slotBuffer: number) {
    this.data.slotBuffer = slotBuffer
  }

  get preBuffer(): number {
    return this.data.preBuffer
  }

  set preBuffer(preBuffer: number) {
    this.data.preBuffer = preBuffer
  }

  get preBufferUnit(): string {
    return this.data.preBufferUnit
  }
  set preBufferUnit(preBufferUnit: string) {
    this.data.preBufferUnit = preBufferUnit
  }

  get slotBufferUnit(): string {
    return this.data.slotBufferUnit
  }
  set slotBufferUnit(slotBufferUnit: string) {
    this.data.slotBufferUnit = slotBufferUnit
  }
  get allowBookingFor(): number {
    return this.data.allowBookingFor
  }

  set allowBookingFor(allowBookingFor: number) {
    this.data.allowBookingFor = allowBookingFor
  }

  get allowBookingForUnit(): string {
    return this.data.allowBookingForUnit
  }

  set allowBookingForUnit(allowBookingForUnit: string) {
    this.data.allowBookingFor = allowBookingForUnit
  }

  get allowBookingAfter(): number {
    return this.data.allowBookingAfter
  }

  set allowBookingAfter(allowBookingAfter: number) {
    this.data.allowBookingAfter = allowBookingAfter
  }

  get allowBookingAfterUnit(): string {
    return this.data.allowBookingAfterUnit
  }

  set allowBookingAfterUnit(allowBookingAfterUnit: string) {
    this.data.allowBookingAfterUnit = allowBookingAfterUnit
  }

  get appoinmentPerSlot(): number {
    return Number(this.data.appoinmentPerSlot) || 1
  }

  set appoinmentPerSlot(appoinmentPerSlot: number) {
    this.data.appoinmentPerSlot = appoinmentPerSlot
  }

  get appoinmentPerDay(): number {
    return this.data.appoinmentPerDay
  }

  set appoinmentPerDay(appoinmentPerDay: number) {
    this.data.appoinmentPerDay = appoinmentPerDay
  }

  get slotDurationUnit(): string {
    return this.data.slotDurationUnit
  }

  set slotDurationUnit(slotDurationUnit: string) {
    this.data.slotDurationUnit = slotDurationUnit
  }

  get order(): number {
    return Number(this.data.order)
  }

  get isActive(): boolean {
    return this.data.isActive !== false
  }

  set isActive(isActive: boolean) {
    this.data.isActive = isActive
  }

  get dateUpdated(): string {
    return this.data.dateUpdated
  }

  get slug(): string {
    return this.data.slug
  }

  get locationId(): string {
    return this.data.locationId
  }

  set locationId(locationId: string) {
    this.data.locationId = locationId
  }

  get enableRecurring(): boolean {
    return this.data.enableRecurring
  }

  set enableRecurring(value: boolean) {
    this.data.enableRecurring = value
  }

  get recurring(): Recurring {
    return this.data.recurring
  }

  set recurring(value: Recurring) {
    this.data.recurring = value
  }

  get isCustomRecurrence(): boolean {
    return !!this.data.recurring?.interval
  }

  get description(): string {
    return this.data.description
  }

  get groupId(): string {
    return this.data.groupId
  }

  get thanksMessage(): string {
    return this.data.thanksMessage
      ? this.data.thanksMessage
      : 'Thank you for your appointment request. We will contact you shortly to confirm your request. Please call our office at {{contactMethod}} if you have any questions.'
  }

  set thanksMessage(thanksMessage: string) {
    this.data.thanksMessage = thanksMessage
  }

  get thankyouURL(): string {
    return this.data.thankyouURL
  }

  set thankyouURL(thankyouURL: string) {
    this.data.thankyouURL = thankyouURL
  }

  get formSubmitType(): FormSubmitType {
    return (
      this.data.formSubmitType ||
      (this.thankyouURL
        ? FormSubmitType.RedirectURL
        : FormSubmitType.ThankYouMessage)
    )
  }

  set formSubmitType(value: FormSubmitType) {
    this.data.formSubmitType = value
  }

  // After form submit either it shows the formSubmitThanksMessage or redirect widget page to the formSubmitRedirectURL
  get formSubmitRedirectURL(): string {
    return this.data.formSubmitRedirectUrl || this.thankyouURL
  }

  set formSubmitRedirectURL(value: string) {
    this.data.formSubmitRedirectUrl = value
  }

  // After form submit either it shows the formSubmitThanksMessage or redirect widget page to the formSubmitRedirectURL
  get formSubmitThanksMessage(): string {
    return this.data.formSubmitThanksMessage || this.thanksMessage
  }

  set formSubmitThanksMessage(value: string) {
    this.data.formSubmitThanksMessage = value
  }

  get stickyContact(): boolean {
    return this.data.stickyContact !== undefined
      ? this.data.stickyContact
      : false
  }

  set stickyContact(stickyContact: boolean) {
    this.data.stickyContact = stickyContact
  }

  get formId(): string {
    return this.data.formId
  }

  set formId(formId: string) {
    this.data.formId = formId
  }

  get codeBlock(): string {
    return this.data.codeBlock
  }

  set codeBlock(codeBlock: string) {
    this.data.codeBlock = codeBlock
  }

  get allowReschedule(): boolean {
    return this.data.allowReschedule ?? true
  }

  set allowReschedule(allowReschedule: boolean) {
    this.data.allowReschedule = allowReschedule
  }

  get allowCancellation(): boolean {
    return this.data.allowCancellation ?? true
  }

  set allowCancellation(allowCancellation: boolean) {
    this.data.allowCancellation = allowCancellation
  }

  get enableStaffSelection(): boolean {
    return this.data.enableStaffSelection
  }

  set enableStaffSelection(enableStaffSelection: boolean) {
    this.data.enableStaffSelection = enableStaffSelection
  }

  get notes(): string {
    const text =
      'notes' in this.data
        ? this.data.notes
        : 'Phone:- {{contact.phone}}\nEmail:- {{contact.email}}\n\nNeed to make a change to this event?\nReschedule:-\n{{reschedule_link}}\n\nCancel:-\n{{cancellation_link}}'
    return text
  }

  set notes(notes: string) {
    this.data.notes = notes
  }

  get pixelId(): string {
    return this.data.pixelId
  }

  set pixelId(pixelId: string) {
    this.data.pixelId = pixelId
  }

  get autoConfirm(): boolean {
    if (this.data.autoConfirm === undefined) {
      return true
    }
    return this.data.autoConfirm
  }

  set autoConfirm(autoConfirm: boolean) {
    this.data.autoConfirm = autoConfirm
  }

  get notifications(): ICalendarNotification[] {
    return this.data.notifications || []
  }

  set notifications(notifications: ICalendarNotification[]) {
    this.data.notifications = notifications
  }

  get eventType(): EventType {
    return this.data.eventType
  }

  set eventType(value: EventType) {
    this.data.eventType = value
  }

  get widgetLogo(): WidgetLogo {
    return this.data.widgetLogo || { url: '', shape: LogoShape.SQUARE }
  }

  set widgetLogo(value: WidgetLogo) {
    this.data.widgetLogo = value
  }

  get reserveService(): boolean {
    return this.data.reserveService !== undefined
      ? this.data.reserveService
      : false
  }

  set reserveService(reserveService: boolean) {
    this.data.reserveService = reserveService
  }

  public get homeServiceData(): HomeServiceData {
    return this.data.homeServiceData === undefined
      ? {}
      : this.data.homeServiceData
  }

  public set homeServiceData(homeServiceData: HomeServiceData) {
    this.data.homeServiceData = homeServiceData
  }

  public get price(): Price {
    return this.data.price === undefined ? {} : this.data.price
  }
  public set price(value: Price) {
    this.data.price = value
  }

  get googleInvitationEmails(): boolean {
    return this.data.googleInvitationEmails ?? false
  }

  set googleInvitationEmails(googleInvitationEmails: boolean) {
    this.data.googleInvitationEmails = googleInvitationEmails
  }

  get shouldAssignContactToTeamMember(): boolean {
    return this.data.shouldAssignContactToTeamMember
  }

  set shouldAssignContactToTeamMember(
    shouldAssignContactToTeamMember: boolean
  ) {
    this.data.shouldAssignContactToTeamMember = shouldAssignContactToTeamMember
  }

  get shouldSkipAssigningContactForExisting(): boolean {
    return this.data.shouldSkipAssigningContactForExisting
  }

  set shouldSkipAssigningContactForExisting(
    shouldSkipAssigningContactForExisting: boolean
  ) {
    this.data.shouldSkipAssigningContactForExisting =
      shouldSkipAssigningContactForExisting
  }

  get enableGuests(): boolean {
    return this.data.enableGuests
  }

  set enableGuests(value: boolean) {
    this.data.enableGuests = value
  }

  get enableMandatoryGuestType(): boolean {
    return this.data.enableMandatoryGuestType
  }

  set enableMandatoryGuestType(value: boolean) {
    this.data.enableMandatoryGuestType = value
  }

  get guestType(): GuestType {
    return this.data.guestType || GuestType.COLLECT_DETAIL
  }

  set guestType(value: GuestType) {
    this.data.guestType = value
  }

  get shouldSendCustomForm(): boolean {
    return this.data.shouldSendCustomForm
  }

  set shouldSendCustomForm(value: boolean) {
    this.data.shouldSendCustomForm = value
  }

  get shouldCollectGuestInfo(): boolean {
    return this.data.enableGuests && this.guestType === GuestType.COLLECT_DETAIL
  }

  get shouldCollectGuestCountOnly(): boolean {
    return this.data.enableGuests && this.guestType === GuestType.COUNT_ONLY
  }
  get enableConsentCheck(): boolean {
    return this.data.enableConsentCheck ?? true
  }

  set enableConsentCheck(value: boolean) {
    this.data.enableConsentCheck = value
  }

  get consentLabel(): string {
    return (
      this.data.consentLabel ||
      'I confirm that I want to receive content from this company using any contact information I provide.'
    )
  }

  set consentLabel(value: string) {
    this.data.consentLabel = value
  }

  get calendarCoverImage(): string {
    return this.data.calendarCoverImage
  }

  set calendarCoverImage(value: string) {
    this.data.calendarCoverImage = value
  }

  get widgetConfig(): WidgetConfig {
    return this.data.widgetConfig
  }

  set widgetConfig(value: WidgetConfig) {
    this.data.widgetConfig = value
  }

  get reschedulePreference(): ReschedulePreference {
    return this.data.reschedulePreference
  }

  set reschedulePreference(value: ReschedulePreference) {
    this.data.reschedulePreference = value
  }

  get cancellationPreference(): CancellationPreference {
    return this.data.cancellationPreference
  }

  set cancellationPreference(value: CancellationPreference) {
    this.data.cancellationPreference = value
  }
  get enableSameUserAssignment(): boolean {
    return this.data.enableSameUserAssignment
  }

  set enableSameUserAssignment(value: boolean) {
    this.data.enableSameUserAssignment = value
  }

  set enableSameUserAssignmentForReschedule(value: boolean) {
    this.data.enableSameUserAssignmentForReschedule = value
  }

  get enableSameUserAssignmentForReschedule(): boolean {
    return this.data.enableSameUserAssignmentForReschedule || false
  }

  get lookBusyConfig(): LookBusyConfig {
    return this.data.lookBusyConfig
  }

  set lookBusyConfig(value: LookBusyConfig) {
    this.data.lookBusyConfig = value
  }

  get notificationStatus(): EmailNotifications {
    return this.data.notificationStatus || []
  }
  set notificationStatus(value: EmailNotifications) {
    this.data.notificationStatus = value
  }
}
