<!-- eslint-disable vue/no-unused-vars -->
<script setup lang="ts">
import AppState from '@/states/app'
import {
  CheckIcon,
  InfoCircleIcon,
  Tag01Icon,
  Trash01Icon,
  Trash03Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  AllExtensions,
  RequiredExtensions,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import {
  UIButton,
  UIDivider,
  UIDropdownTree,
  UIForm,
  UIFormItem,
  UIHeader,
  UIInput,
  UIInputGroup,
  UIInputGroupLabel,
  UIRadio,
  UISelect,
  UITextSmRegular,
  UITextXsRegular,
  UITooltip,
  UIUpload,
} from '@gohighlevel/ghl-ui'
import { History } from '@tiptap/extension-history'
import { Editor } from '@tiptap/vue-3'
import DOMPurify from 'dompurify'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import { CalendarService } from '../../class/services/CalendarService'
import {
  CalendarType,
  LocationConfigurations,
  LogoShape,
  TeamMember,
} from '../../models/calendar'
import { EVENT_COLORS } from '../../utils/constants'
import {
  calendarNameFieldOptions,
  handleCustomValueModification,
  meetingInviteTitleFieldOptionsEvent,
  meetingInviteTitleFieldOptionsV3,
  meetingLocationFieldOptionsEvent,
} from '../../utils/customValueMapper'
import { getTooltip } from '../../utils/generalFunctions'
import { hasDuplicateKeyValue, hasEmptyKeys } from '../../utils/validation'

import TextEditorMenu from '../common/TextEditorMenu.vue'
import TeamMemberPicker from './TeamMemberPicker.vue'

const teamMemberPicker = ref()
const isAliasFieldEnabled = ref<number[]>([])
const formRef = ref()
const { t } = useI18n()
const toast = useToast()

const meetingLocationNew = ref({
  custom: '',
  inbound_call: '{{location.phone}}',
  outbound_call: '{{location.phone}}',
  physical: '{{location.full_address}}',
})

const meetingLocationTypesEvent = [
  {
    label: 'Phone',
    value: 'inbound_call',
  },
  {
    label: 'Full Address',
    value: 'physical',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
  {
    label: 'Ask the Booker',
    value: 'booker',
  },
]
const editor = ref<any>(null)

const state = reactive({
  isLoading: false,
  isSlugValidating: false,
  collectiveAddress: '' as string | undefined,
  selectedTeamMembers: [] as TeamMember[],
  formData: {
    name: '',
    eventColor: '#D50000',
    eventTitle: '',
    meetingLocation: '',
    locationConfigurations: [
      {
        kind: 'custom',
        location: '',
        position: 0,
        meetingId: 'custom_0',
      },
    ] as LocationConfigurations[],
    groupId: '',
    description: '',
    widgetSlug: '',
    fileList: [],
    widgetLogo: {
      shape: LogoShape.SQUARE,
      url: '',
    },
  },
  enableSameUserAssignment: false,
  enableSameUserAssignmentForReschedule: false,
})

const intializeEditor = () => {
  editor.value = new Editor({
    content: editingCalendar.value?.description || '',
    parseOptions: {
      preserveWhitespace: 'full',
    },
    extensions: [
      History,
      RequiredExtensions,
      AllExtensions.configure({
        placeholder: {
          placeholder: t('create_calendar.description_placeholder'),
          showForAllNodes: false,
        },
        divTag: {
          inline: false,
          preserveWhitespace: false,
          HTMLAttributes: {
            style: 'color:#10182899',
          },
        },
        preTag: {
          inline: false,
          preserveWhitespace: false,
          HTMLAttributes: {
            style: 'color:#10182899',
          },
        },
        textAlignment: { types: ['heading', 'paragraph'] },
        paragraph: {
          HTMLAttributes: {
            style: 'margin:0px;color:#10182899',
          },
        },
        heading: {
          HTMLAttributes: {
            style: 'color:#10182899',
          },
        },
        link: {
          HTMLAttributes: {
            target: '_blank',
          },
        },
      }),
    ],
    onUpdate({ editor }) {
      state.formData.description = editor.getHTML()
    },
  })
}

const groupsOptions = computed(() => {
  return (AppState.globalTeams as any)?.teams?.map((x: any) => {
    return {
      value: x.id,
      label: x.name,
    }
  })
})

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const emit = defineEmits(['onSave', 'onCancel'])

const methods = {
  async loadData() {
    if (editingCalendar.value && !editor.value) {
      intializeEditor()
    }

    state.formData.name = editingCalendar.value?.name || ''
    state.formData.description = editingCalendar.value?.description || ''
    state.formData.groupId = editingCalendar.value?.groupId || ''
    state.formData.widgetSlug = editingCalendar.value?.widgetSlug || ''
    state.formData.eventTitle = editingCalendar.value?.eventTitle || ''
    state.formData.meetingLocation =
      editingCalendar.value?.meetingLocation || ''
    state.formData.locationConfigurations = editingCalendar.value
      ?.locationConfigurations?.length
      ? (editingCalendar.value
          ?.locationConfigurations as LocationConfigurations[])
      : [
          {
            kind: 'custom',
            location: editingCalendar.value?.meetingLocation || '',
            position: 0,
            meetingId: 'custom_0',
          },
        ]

    if (editingCalendar.value?.calendarType === CalendarType.COLLECTIVE) {
      // state.formData.meetingLocation =
      //   editingCalendar.value.teamMembers[0].meetingLocation
      state.selectedTeamMembers = editingCalendar.value?.teamMembers
      state.collectiveAddress = state.selectedTeamMembers.find(
        x => x.useMeetingForCollective
      )?.userId
    }

    state.formData.eventColor = editingCalendar.value?.eventColor || '#D50000'

    state.formData.widgetLogo.url = editingCalendar.value?.widgetLogo?.url || ''
    state.formData.widgetLogo.shape =
      editingCalendar.value?.widgetLogo?.shape || LogoShape.SQUARE
    state.enableSameUserAssignment =
      editingCalendar.value?.enableSameUserAssignment || false
    state.enableSameUserAssignmentForReschedule =
      editingCalendar.value?.enableSameUserAssignmentForReschedule || false
  },
  async handleLogoUpload(file: any) {
    const formData = new FormData()
    formData.append('logo', file.file.file)
    const {
      data: { uploadedUrl },
    } = await CalendarService.uploadCalendarLogo(
      editingCalendar.value!.id,
      formData,
      editingCalendar?.value?.locationId
    )

    state.formData.widgetLogo.url = uploadedUrl
  },
  async removeLogo() {
    state.formData.widgetLogo.url = ''
  },
  async emitSave() {
    if (state.isSlugValidating) {
      return
    }
    if (
      editingCalendar.value?.calendarType === CalendarType.EVENT &&
      hasEmptyKeys(state.formData?.locationConfigurations)
    ) {
      toast.error('Please enter a value for all location types')
      return
    }
    if (
      editingCalendar.value?.calendarType === CalendarType.EVENT &&
      hasDuplicateKeyValue(state.formData?.locationConfigurations)
    ) {
      toast.error('Duplicate value for same location type is not allowed')
      return
    }
    try {
      await formRef.value.getForm().validate()
      isAliasFieldEnabled.value = []
    } catch (error) {
      return
    }
    const sanitizedDescription = DOMPurify.sanitize(state.formData.description)
    const payload = {
      name: state.formData.name,
      description: sanitizedDescription,
      groupId: state.formData.groupId,
      widgetSlug: state.formData.widgetSlug,
      eventTitle: state.formData.eventTitle,
      eventColor: state.formData.eventColor,
      widgetLogo: state.formData.widgetLogo,
      enableSameUserAssignment: state.enableSameUserAssignment,
      enableSameUserAssignmentForReschedule:
        state.enableSameUserAssignmentForReschedule,
    } as Record<string, any>

    if (
      editingCalendar.value?.widgetLogo.url &&
      editingCalendar.value?.widgetLogo.url !== state.formData.widgetLogo.url
    ) {
      await CalendarService.removeCalendarLogo(
        editingCalendar.value!.id,
        AppState.locationId
      )
    }

    if (editingCalendar.value?.calendarType === CalendarType.EVENT) {
      payload.meetingLocation = ''
      payload.locationConfigurations =
        state.formData?.locationConfigurations.map(x => {
          return {
            kind: x?.kind,
            location: x?.location,
            position: x?.position,
            meetingId: x?.kind + '_' + x?.position,
            ...(x?.alias && { alias: x?.alias }),
          }
        })
    } else {
      payload.teamMembers =
        await teamMemberPicker.value.methods.getTeamMembers()
      if (!payload.teamMembers) {
        return null
      }

      if (editingCalendar.value?.calendarType === CalendarType.COLLECTIVE) {
        payload.teamMembers = payload.teamMembers.map((x: TeamMember) => {
          x.useMeetingForCollective = x.userId === state.collectiveAddress
          return x
        })
      }

      payload.eventType = teamMemberPicker.value.methods.getEventType()

      if (payload.teamMembers.length > 1) {
        payload.enableRecurring = false
      }
    }

    return payload
  },

  updateSelectedTeamMember(teamMembers) {
    state.selectedTeamMembers = teamMembers
    const selectedTeamMember = state.selectedTeamMembers.find(
      x => x.userId === state.collectiveAddress
    )
    state.collectiveAddress = selectedTeamMember ? state.collectiveAddress : ''
  },
  updateAdvancedSchedulingSettings(settings) {
    state.enableSameUserAssignment = settings.enableSameUserAssignment
    state.enableSameUserAssignmentForReschedule =
      settings.enableSameUserAssignmentForReschedule
  },
  openAliasField(index: number) {
    isAliasFieldEnabled.value.push(index)
  },
  closeAliasField(index: number) {
    isAliasFieldEnabled.value = isAliasFieldEnabled.value.filter(
      x => x !== index
    )
  },
}

defineExpose({
  methods,
})
const rules = computed(() => {
  return {
    name: {
      validator() {
        if (!state.formData.name?.trim()) {
          return new Error('Please enter a name')
        }

        return true
      },
      message: t('calendar_advanced.meeting_details.enter_a_name'),
      trigger: 'blur',
    },
    eventTitle: {
      required: true,
      message: t('calendar_advanced.meeting_details.enter_an_event_title'),
      trigger: 'blur',
    },
    widgetSlug: {
      required: true,
      trigger: ['input', 'blur'],
      asyncValidator() {
        return new Promise(async (resolve, reject) => {
          state.isSlugValidating = true

          if (!state.formData.widgetSlug) {
            state.isSlugValidating = false
            return reject(new Error('Slug is required'))
          }

          const slugRegex = /^[a-z0-9-_]+(\/[a-z0-9-_]+)*$/

          if (!slugRegex.test(state.formData.widgetSlug)) {
            state.isSlugValidating = false
            return reject(new Error('This slug is not valid'))
          }

          try {
            const { data } = await CalendarService.isValidCalendarSlug(
              state.formData.widgetSlug,
              editingCalendar.value?.id
            )
            if (!data?.available) {
              return reject(new Error('This slug is already taken'))
            }
          } catch (error) {
            return reject(error)
          }

          state.isSlugValidating = false
          return resolve(true)
        })
      },
    },
  }
})

const MEETING_LOCATION_TYPES = ref({
  custom: t('calendar_advanced.meeting_details.custom'),
  zoom_conference: 'Zoom',
  ms_teams_conference: 'Microsoft Teams',
  google_conference: 'Google Meet',
  inbound_call: 'Phone',
  outbound_call: 'Phone Outgoing',
  physical: 'Full Address',
})

const collectiveMeetingLocations = computed(() => {
  return state.selectedTeamMembers?.map((t: any) => {
    const user = (AppState.globalUsers as any).users.find(
      (u: any) => u.id === t.userId
    )

    const locationConfig: LocationConfigurations = t?.locationConfigurations[0]

    return {
      value: t.userId,
      label: `${user?.name}  (${
        MEETING_LOCATION_TYPES.value[locationConfig.kind]
      }) `,
    }
  })
})

const calendarNameValuesRef = ref()
const meetingInviteTitleRef = ref()
const meetingLocationEventRef = ref()

watchEffect(() => {
  const customValues = handleCustomValueModification(AppState.customValues)
  const meetingInviteTitleOptions = editingCalendar.value?.isEventCalendar
    ? meetingInviteTitleFieldOptionsEvent
    : meetingInviteTitleFieldOptionsV3
  calendarNameValuesRef.value = customValues
    ? [...calendarNameFieldOptions, customValues]
    : calendarNameFieldOptions
  meetingLocationEventRef.value = customValues
    ? [...meetingLocationFieldOptionsEvent, customValues]
    : meetingLocationFieldOptionsEvent
  meetingInviteTitleRef.value = customValues
    ? [...meetingInviteTitleOptions, customValues]
    : meetingInviteTitleOptions
})

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  },
  { immediate: true }
)

watchEffect(() => {
  AppState.isMultipleLocationEnabled =
    state.formData.locationConfigurations.length > 1
})

const isServiceCalendar = computed(() => {
  return editingCalendar.value?.isServiceCalendar
})
</script>

<template>
  <UIHeader
    id="text-only-header"
    :secondary-title="
      isServiceCalendar
        ? t('calendar_advanced.meeting_details.service_details')
        : t('calendar_advanced.meeting_details.meeting_details')
    "
    :description="
      t('calendar_advanced.meeting_details.meeting_details_description')
    "
  />
  <UIForm
    id="create-calendar-form"
    ref="formRef"
    :model="state.formData"
    :rules="rules"
    class="w-10/12"
  >
    <UIFormItem
      :label="
        isServiceCalendar
          ? $t('create_calendar.service_logo')
          : $t('create_calendar.calendar_logo')
      "
    >
      <UIUpload
        v-if="!state.formData.widgetLogo.url"
        :file-list="state.formData.fileList"
        :multiple="false"
        accept=".svg,.png,.jpg,.gif"
        class="ui-upload-container"
        @change="methods.handleLogoUpload"
      >
        <template #extra>
          <UITextSmRegular>
            <span class="font-semibold text-blue-600">
              {{ $t('calendar_advanced.meeting_details.click_to_upload') }}
            </span>
            {{ $t('calendar_advanced.meeting_details.or_drag_and_drop') }}
          </UITextSmRegular>
          <UITextXsRegular>
            PNG, JPEG, JPG or GIF (max. dimensions 180x180px | max. size 2.5mb)
          </UITextXsRegular>
        </template>
      </UIUpload>
      <div v-else class="flex items-start space-x-10">
        <img
          :src="state.formData.widgetLogo.url"
          class="h-40 w-36 object-fill"
          :class="{
            'rounded-full': state.formData.widgetLogo.shape === LogoShape.ROUND,
            'rounded-lg': state.formData.widgetLogo.shape === LogoShape.SQUARE,
          }"
          :alt="'create-logo'"
        />

        <div class="flex flex-col justify-start">
          <UIFormItem label="Widget shape:">
            <div class="flex w-full flex-col space-y-4">
              <div class="flex space-x-4">
                <UIRadio
                  id="calendar-widget-logo-square"
                  :checked="
                    state.formData.widgetLogo.shape === LogoShape.SQUARE
                  "
                  :value="LogoShape.SQUARE"
                  :name="LogoShape.SQUARE"
                  @change="state.formData.widgetLogo.shape = LogoShape.SQUARE"
                >
                  {{ $t('calendar_advanced.meeting_details.square') }}
                </UIRadio>

                <UIRadio
                  id="calendar-widget-logo-round"
                  :checked="state.formData.widgetLogo.shape === LogoShape.ROUND"
                  :value="LogoShape.ROUND"
                  :name="LogoShape.ROUND"
                  @change="state.formData.widgetLogo.shape = LogoShape.ROUND"
                >
                  {{ $t('calendar_advanced.meeting_details.circle') }}
                </UIRadio>
              </div>
            </div>
          </UIFormItem>

          <UIButton
            id="calendar-logo-remove-btn"
            class="border-red-300 text-red-700"
            @click="methods.removeLogo"
          >
            <Trash01Icon class="mr-2 w-5" />
            <div class="">
              {{ $t('calendar_advanced.meeting_details.remove_logo') }}
            </div>
          </UIButton>
        </div>
      </div>
    </UIFormItem>
    <UIFormItem
      :label="
        isServiceCalendar
          ? $t('create_calendar.service_calendar_name')
          : $t('create_calendar.calendar_name')
      "
      path="name"
    >
      <UIInput
        id="calendar-name-input"
        v-model="state.formData.name"
        :placeholder="$t('create_calendar.eg_outbound_reach')"
      >
        <template #suffix>
          <UIDropdownTree
            :options="calendarNameValuesRef"
            :path-separator="'/'"
            :disable-search="false"
            :disabled="undefined"
            :trigger-label="undefined"
            :debug="undefined"
            :placement="undefined"
            :loading="undefined"
            :remote="undefined"
            :search-placeholder="''"
            @on-select="(value: string) => {
    state.formData.name = state.formData.name + ' ' + value
  }"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template #trigger="triggerProps">
              <UIButton id="dd" quaternary>
                <Tag01Icon class="h-5 w-5 lg:-mr-2" />
              </UIButton>
            </template>
          </UIDropdownTree>
        </template>
      </UIInput>
    </UIFormItem>

    <UIFormItem :label="$t('create_calendar.description')">
      <div class="flex w-full flex-col">
        <TextEditorMenu :editor="editor" />
        <UIRichTextEditor
          :editor="editor"
          container-class="force-border-0 overflow-x-auto custom-editor-styling"
        />
      </div>
    </UIFormItem>
    <UIDivider />

    <UIFormItem :label="$t('create_calendar.group')">
      <UISelect
        id="calendar-group-select"
        v-model:value="state.formData.groupId"
        :options="groupsOptions"
        :filterable="true"
      />
    </UIFormItem>

    <UIFormItem
      :label="$t('create_calendar.custom_url')"
      path="widgetSlug"
      class="pb-2"
    >
      <UIInputGroup>
        <UIInputGroupLabel>/widget/bookings/</UIInputGroupLabel>
        <UIInput
          id="calendarUrl"
          :model-value="state.formData.widgetSlug"
          placeholder="my-calendar"
          @update:model-value="state.formData.widgetSlug = $event.toLowerCase()"
        />
      </UIInputGroup>
    </UIFormItem>

    <UIFormItem
      :label="
        isServiceCalendar
          ? $t('create_calendar.appointment_invite_title')
          : $t('create_calendar.meeting_invite_title')
      "
      :tooltip="
        getTooltip(
          $t('calendar_advanced.meeting_details.meeting_invite_title_tooltip')
        )
      "
      path="eventTitle"
    >
      <UIInput
        id="calendar-event-title-input"
        v-model="state.formData.eventTitle"
        placeholder="{{ contact.name }}"
      >
        <template #suffix>
          <UIDropdownTree
            :options="meetingInviteTitleRef"
            :path-separator="'/'"
            :disable-search="false"
            :disabled="undefined"
            :trigger-label="undefined"
            :debug="undefined"
            :placement="undefined"
            :loading="undefined"
            :remote="undefined"
            :search-placeholder="''"
            @on-select="(value: string) => {
    state.formData.eventTitle = state.formData.eventTitle + ' ' + value
  }"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template #trigger="triggerProps">
              <UIButton id="dd" quaternary>
                <Tag01Icon class="h-5 w-5 lg:-mr-2" />
              </UIButton>
            </template>
          </UIDropdownTree>
        </template>
      </UIInput>
    </UIFormItem>

    <UIFormItem
      v-if="editingCalendar?.calendarType === CalendarType.EVENT"
      :label="$t('calendar_advanced.meeting_details.meeting_location')"
    >
      <div class="w-full">
        <div
          v-for="(_, index) in state.formData.locationConfigurations"
          :key="index"
          class="mb-4 w-full gap-3"
        >
          <div class="flex w-full gap-3">
            <UISelect
              id="calendar-event-mutli-select"
              v-model:value="state.formData.locationConfigurations[index].kind"
              :options="meetingLocationTypesEvent"
              class="w-1/2 xl:w-1/3"
              @change="(value: string) => {
    if (value === 'booker' && state.formData.locationConfigurations.findIndex(item => item.kind === 'booker') > -1) {
      state.formData.locationConfigurations.splice(index, 1)
      return toast.error($t('calendar_advanced.meeting_details.ask_the_booker_error_message'))
    }
    state.formData.locationConfigurations[index].location =
      meetingLocationNew[value]
  }
    "
            />
            <div class="flex flex-1 flex-col gap-3">
              <div class="flex flex-1 gap-3">
                <UIInput
                  v-if="
                    ['inbound_call', 'physical'].includes(
                      state.formData.locationConfigurations[index].kind
                    )
                  "
                  id="calendar-event-title-input"
                  v-model="
                    state.formData.locationConfigurations[index].location
                  "
                  :placeholder="
                    $t('calendar_advanced.meeting_details.enter_location')
                  "
                  class="flex-1"
                />
                <UIInputGroup
                  v-if="
                    state.formData.locationConfigurations[index].kind ===
                    'custom'
                  "
                  v-bind="{}"
                  style="max-width: 400px"
                  class="flex-1"
                >
                  <UIInput
                    v-model="
                      state.formData.locationConfigurations[index].location
                    "
                    :placeholder="
                      $t('calendar_advanced.meeting_details.enter_location')
                    "
                    class="flex-1"
                  >
                    <template #suffix>
                      <UIDropdownTree
                        :options="meetingLocationEventRef"
                        :path-separator="'/'"
                        :disable-search="false"
                        :disabled="undefined"
                        :trigger-label="undefined"
                        :debug="undefined"
                        :placement="undefined"
                        :loading="undefined"
                        :remote="undefined"
                        :search-placeholder="''"
                        @on-select="(value: string) => {
    state.formData.locationConfigurations[index].location =
      state.formData.locationConfigurations[index].location +
      ' ' +
      value
  }"
                      >
                        <!-- eslint-disable-next-line vue/no-unused-vars -->
                        <template #trigger="triggerProps">
                          <UIButton id="dd" quaternary>
                            <Tag01Icon class="h-5 w-5 lg:-mr-2" />
                          </UIButton>
                        </template>
                      </UIDropdownTree>
                    </template>
                  </UIInput>
                  <UIButton
                    class="h-full"
                    :disabled="
                      isAliasFieldEnabled.includes(index) ||
                      state.formData.locationConfigurations[index].alias?.length
                    "
                    @click="methods.openAliasField(index)"
                  >
                    <span class="text-primary-600">
                      {{
                        $t(
                          'calendar_advanced.meeting_details.add_display_label'
                        )
                      }}
                    </span>
                  </UIButton>
                </UIInputGroup>
                <UIButton
                  id="remove-location"
                  class="h-10 w-10 rounded-lg bg-gray-100 text-black"
                  :text="true"
                  aria-labelledby="remove-location"
                  @click="
                    state.formData.locationConfigurations.splice(index, 1),
                      methods.closeAliasField(index)
                  "
                >
                  <Trash03Icon class="w-4" />
                </UIButton>
              </div>
              <div
                v-if="
                  isAliasFieldEnabled.includes(index) ||
                  state.formData.locationConfigurations[index].alias
                "
                class="flex flex-1 gap-3"
              >
                <UIInput
                  v-model="state.formData.locationConfigurations[index].alias"
                  :placeholder="
                    $t('calendar_advanced.meeting_details.enter_display_label')
                  "
                />
                <UIButton
                  id="remove-location"
                  class="h-10 w-10 rounded-lg bg-gray-100 text-black"
                  :text="true"
                  aria-labelledby="remove-location"
                  @click="
                    methods.closeAliasField(index),
                      (state.formData.locationConfigurations[index].alias = '')
                  "
                >
                  <Trash03Icon class="w-4" />
                </UIButton>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <UIButton
            :id="'add-location-btn'"
            :type="'tertiary'"
            :size="'medium'"
            :disabled="false"
            :loading="false"
            :ghost="false"
            :quaternary="false"
            :circle="false"
            :text="true"
            :link-gray="undefined"
            :style="{ backgroundColor: '#EFF4FF' }"
            class="rounded px-4 py-3"
            @click="
              state.formData.locationConfigurations.push({
                kind: 'custom',
                location: '',
                position: state.formData.locationConfigurations.length,
                meetingId:
                  'custom' + '_' + state.formData.locationConfigurations.length,
              })
            "
          >
            {{ $t('calendar_advanced.meeting_details.add_location') }}
          </UIButton>
          <UITooltip>
            <template #trigger>
              <InfoCircleIcon class="h-4 w-4 text-primary-600" />
            </template>
            <p>
              {{
                $t(
                  'calendar_advanced.meeting_details.multiple_location_support_message'
                )
              }}
            </p>
          </UITooltip>
        </div>
      </div>
    </UIFormItem>

    <TeamMemberPicker
      ref="teamMemberPicker"
      @on-team-member-select="methods.updateSelectedTeamMember"
      @on-advanced-scheduling-settings-update="
        methods.updateAdvancedSchedulingSettings
      "
    />
    <UIFormItem
      v-if="editingCalendar?.calendarType === CalendarType.COLLECTIVE"
      :label="$t('calendar_advanced.meeting_details.meeting_location')"
    >
      <UISelect
        id="calendar-collective-address-select"
        v-model:value="state.collectiveAddress"
        :options="collectiveMeetingLocations"
      />
    </UIFormItem>

    <UIFormItem
      class="mt-4"
      :label="$t('create_calendar.event_color')"
      :tooltip="
        getTooltip($t('calendar_advanced.meeting_details.event_color_tooltip'))
      "
    >
      <div
        v-for="color in EVENT_COLORS"
        :key="color.colorName"
        class="mr-1 flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border"
        :style="{
          'background-color': color.colorHexCode,
        }"
        role="button"
        :aria-labelledby="'event-color-' + color.colorName"
        :title="color.colorName"
        :aria-label="color.colorHexCode"
        @click="state.formData.eventColor = color.colorHexCode"
        @keypress="state.formData.eventColor = color.colorHexCode"
      >
        <CheckIcon
          v-if="state.formData.eventColor === color.colorHexCode"
          class="h-6 w-6 text-white"
        />
      </div>
    </UIFormItem>
  </UIForm>
</template>

<style lang="scss">
.ui-upload-container .upload-icon svg {
  padding: 0px !important;
}

#create-calendar-form {
  .force-border-0 {
    border: 1px solid #d0d5dd !important;
    padding: 15px !important;
    max-height: 200px !important;
    min-height: 100px !important;
  }

  .ProseMirror p {
    margin: 0;
    font-size: 13px !important;
  }

  .ProseMirror ol {
    font-size: 14px !important;
  }

  .v3-search input::placeholder {
    font-size: 1rem;
    text-align: left;
    font-weight: normal;
    font-size: 0.875rem;
  }
}
</style>
