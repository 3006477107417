export interface FeatureFlags {
  isServiceMenuEnabled?: boolean
  isRoomEnabled?: boolean
  isEquipmentEnabled?: boolean
}

const defaultFeatureFlags: FeatureFlags = {
  isServiceMenuEnabled: false,
  isRoomEnabled: false,
  isEquipmentEnabled: false,
}

export default class CalendarLocationSettings {
  private data: any = {}

  constructor(data: any) {
    this.data = data
  }

  get featureFlags(): FeatureFlags {
    return this.data?.featureFlags ?? defaultFeatureFlags
  }

  get allowTimeFormatChange(): boolean {
    return this.data?.allowTimeFormatChange ?? false
  }
  set allowTimeFormatChange(value: boolean) {
    this.data.allowTimeFormatChange = value
  }
}
