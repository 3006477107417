<script setup lang="ts">
import { getInitials } from '@/utils/generalFunctions'
import {
  InfoCircleIcon,
  Settings01Icon,
  Tag01Icon,
  Trash03Icon,
  UserCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIAvatar,
  UIButton,
  UIDivider,
  UIDropdownTree,
  UIForm,
  UIFormItem,
  UIInput,
  UIInputGroup,
  UIRadio,
  UISelect,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { TYPE, useToast } from 'vue-toastification'
import { CalendarType, EventType, TeamMember } from '../../models/calendar'
import AppState, {
  fetchConnections,
  setEditCalendarValidationData,
} from '../../states/app'
import {
  handleCustomValueModification,
  meetingLocationFieldOptions,
} from '../../utils/customValueMapper'
import { getDefaultTeamMember } from '../../utils/generalFunctions'
import { hasDuplicateKeyValue, hasEmptyKeys } from '../../utils/validation'
import AdvancedCalendarScheduleSettingsModal from '../Settings/AdvancedCalendarScheduleSettingsModal.vue'
const formRef = ref()
const emptyLocationConfigFieldsIndex = ref([])

const meetingLocationNew = ref({
  custom: '',
  inbound_call: '{{location.phone}}',
  outbound_call: '{{location.phone}}',
  physical: '{{location.full_address}}',
})

const { t } = useI18n()

const state = reactive({
  isLoading: false,
  zoomConnections: [] as any,
  teamMembers: [] as TeamMember[],
  formData: {},
  eventType: EventType.RoundRobin_OptimizeForAvailability,
  customMeetingLocation: {},
  showAdvancedSettings: false,
  msTeamsConnections: [] as any,
})

const toast = useToast()

const meetingLocationRef = ref()
watchEffect(() => {
  const customValues = handleCustomValueModification(AppState.customValues)
  meetingLocationRef.value = customValues
    ? [...meetingLocationFieldOptions, customValues]
    : meetingLocationFieldOptions
})

const emit = defineEmits([
  'onTeamMemberSelect',
  'onAdvancedSchedulingSettingsUpdate',
])
const usersInLocationOptions = computed(() => {
  return (AppState.globalUsers as any)?.users.map((x: any) => {
    return {
      value: x.id,
      label: x.name,
      disabled:
        (state.teamMembers.length === 1 &&
          state.teamMembers[0].locationConfigurations.length > 1) ||
        ((selectedCalendarType.value === CalendarType.CLASS_BOOKING ||
          selectedCalendarType.value === CalendarType.PERSONAL ||
          AppState.editCalendarValidationData.enableRecurring) &&
          state.teamMembers.length === 1 &&
          !state.teamMembers.some(y => y.userId === x.id)),
    }
  })
})

const priorityOptions = [
  {
    label: t('calendar_advanced.meeting_details.high_priority'),
    value: 1,
  },
  {
    label: t('calendar_advanced.meeting_details.medium_priority'),
    value: 0.5,
  },
  {
    label: t('calendar_advanced.meeting_details.low_priority'),
    value: 0,
  },
]

const oldMeetingOptions = {
  zoom_conference: 'true',
  ms_teams_conference: 'true',
  custom: 'false',
  google_conference: 'meet',
  inbound_call: 'phone',
  physical: 'address',
}

const isAliasFieldEnabled = ref<string[]>([])

const meetingLocationOptions = computed(() => {
  const options = [
    {
      label: t('calendar_advanced.meeting_details.custom'),
      value: 'custom',
    },
    {
      label: 'Zoom',
      value: 'zoom_conference',
      disabled: selectedCalendarType.value === CalendarType.CLASS_BOOKING,
    },
    {
      label: 'Google Meet',
      value: 'google_conference',
      disabled: selectedCalendarType.value === CalendarType.CLASS_BOOKING,
    },
    {
      label: 'Phone',
      value: 'inbound_call',
      disabled: selectedCalendarType.value === CalendarType.CLASS_BOOKING,
    },
    {
      label: 'Full Address',
      value: 'physical',
      disabled: selectedCalendarType.value === CalendarType.CLASS_BOOKING,
    },
    {
      label: 'Ask the Booker',
      value: 'booker',
      disabled:
        (selectedCalendarType.value !== CalendarType.ROUND_ROBIN &&
          selectedCalendarType.value !== CalendarType.PERSONAL) ||
        state.teamMembers.length > 1,
    },
    {
      label: 'Microsoft Teams',
      value: 'ms_teams_conference',
      disabled: selectedCalendarType.value === CalendarType.CLASS_BOOKING,
    },
  ]
  return options
})

const editingCalendar = computed(() => {
  return AppState.editingCalendar
})

const selectedCalendarType = computed(() => {
  return editingCalendar.value?.calendarType
})

const teamMembersWithFullDetails = computed(() => {
  return state.teamMembers.map(x => {
    const user = (AppState.globalUsers as any).users.find(
      (y: any) => y.id === x.userId
    )
    return {
      ...x,
      ...user,
    }
  })
})

const methods = {
  async loadData() {
    await Promise.all([methods.loadConnection()])

    state.teamMembers =
      editingCalendar.value?.teamMembers
        ?.filter(x => x.selected)
        ?.map(x => {
          let priority = Number(x.priority)
          if (isNaN(priority)) {
            priority = 0.5
          }
          return { ...x, priority }
        })
        ?.filter(x =>
          AppState.globalUsers?.users.map(y => y.id).includes(x.userId)
        ) || []
    state.eventType =
      editingCalendar.value?.eventType ||
      EventType.RoundRobin_OptimizeForAvailability
  },
  priorityChanged(userId, value) {
    state.teamMembers = state.teamMembers.map(x => {
      if (x.userId !== userId) {
        return x
      }

      return {
        ...x,
        priority: value,
      }
    })
  },
  isPrimaryChanged(userId, value) {
    state.teamMembers = state.teamMembers.map(x => {
      if (x.userId !== userId) {
        return {
          ...x,
          isPrimary: !value,
        }
      }

      return {
        ...x,
        isPrimary: value,
      }
    })
  },

  meetingLocationTypeChangeNew(userId: string, value: string, index: number) {
    state.teamMembers = state.teamMembers.map(x => {
      if (x.userId !== userId) {
        return x
      }
      if (
        [
          'zoom_conference',
          'google_conference',
          'booker',
          'ms_teams_conference',
        ]?.indexOf(value) !== -1 &&
        x.locationConfigurations.find(y => y.kind === value)
      ) {
        toast(t('calendar_advanced.meeting_details.location_exists_toast'), {
          type: TYPE.ERROR,
        })
        return x
      }
      const zoomConnection = state.zoomConnections.find(
        connection => connection.ownerId === x.userId
      )

      if (!zoomConnection && value === 'zoom_conference') {
        toast(t('calendar_advanced.meeting_details.zoom_not_added'), {
          type: TYPE.ERROR,
        })
        return x
      }
      const msTeamsConnection = state.msTeamsConnections.find(
        connection => connection.ownerId === x.userId
      )
      if (!msTeamsConnection && value === 'ms_teams_conference') {
        toast(t('calendar_advanced.meeting_details.ms_teams_not_added'), {
          type: TYPE.ERROR,
        })
        return x
      }

      if (value === 'zoom_conference') {
        x.isZoomAdded = String(oldMeetingOptions[value])
        x.locationConfigurations[index].zoomOauthId =
          value === 'zoom_conference' ? zoomConnection?.oauthId || '' : ''
      }

      if (value === 'ms_teams_conference') {
        x.isMsTeamsAdded = String(oldMeetingOptions[value])
        x.locationConfigurations[index].msTeamsOauthId =
          value === 'ms_teams_conference'
            ? msTeamsConnection?.oauthId || ''
            : ''
      }
      x.locationConfigurations[index].kind = value

      if (
        ['physical', 'inbound_call', 'outbound_call', 'custom'].includes(value)
      ) {
        x.locationConfigurations[index].location =
          meetingLocationNew.value[value]
        x.meetingLocation = meetingLocationNew.value[value]
      }
      return x
    })
  },
  meetingLocationChanged(userId, value) {
    state.teamMembers = state.teamMembers.map(x => {
      if (x.userId !== userId) {
        return x
      }

      x.locationConfigurations[0].location = value

      return {
        ...x,
        meetingLocation: value,
      }
    })
  },
  emitAdvancedFormData(settings) {
    emit('onAdvancedSchedulingSettingsUpdate', settings)
  },
  // eslint-disable-next-line max-params
  meetingLocationChangedNew(
    userId: string,
    value: string,
    index: number,
    kind: string
  ) {
    state.teamMembers = state.teamMembers.map(x => {
      if (x.userId !== userId) {
        return x
      }
      x.locationConfigurations[index].location = value
      if (kind === 'custom') {
        meetingLocationNew.value.custom = value
      }

      return {
        ...x,
        meetingLocation: value,
      }
    })
  },
  meetingAliasChanged(userId: string, value: string, index: number) {
    state.teamMembers = state.teamMembers.map(x => {
      if (x.userId !== userId) {
        return x
      }

      x.locationConfigurations[index].alias = value

      return {
        ...x,
      }
    })
  },
  removeTeamMember(userId) {
    state.teamMembers = state.teamMembers.filter(x => x.userId !== userId)
    emit('onTeamMemberSelect', state.teamMembers)
  },

  // HERE 2
  teamMembersChanged(value) {
    let addedTeamMembers = []
    const existingTeamMemberIds = state.teamMembers.map(x => x.userId)

    const removedTeamMembers = existingTeamMemberIds.filter(
      x => !value.includes(x)
    )

    const isPrimary = state.teamMembers.some(
      x => x.isPrimary && removedTeamMembers.includes(x.userId)
    )

    if (selectedCalendarType.value === CalendarType.COLLECTIVE && isPrimary) {
      return
    }
    addedTeamMembers = value.filter(x => !existingTeamMemberIds.includes(x))

    state.teamMembers = state.teamMembers.filter(
      x => !removedTeamMembers.includes(x.userId)
    )
    state.teamMembers = [
      ...(state.teamMembers || []),
      ...(addedTeamMembers || []).map(x => {
        const teamMemberDetail = (AppState.globalUsers as any).users.find(
          obj => obj.id === x
        )
        return processTeamMemberInfo(teamMemberDetail, x)
      }),
    ]
    //}
    emit('onTeamMemberSelect', state.teamMembers)
  },
  async loadConnection() {
    await fetchConnections(AppState.locationId)
    const zoomConnections =
      AppState.connections?.locationConnections?.zoom || []
    const msTeamsConnections =
      AppState.connections?.locationConnections?.ms_teams || []

    state.msTeamsConnections = msTeamsConnections

    state.zoomConnections = zoomConnections
  },
  async getTeamMembers() {
    try {
      await formRef.value.getForm().validate()
      isAliasFieldEnabled.value = []
      if (
        (selectedCalendarType.value === CalendarType.ROUND_ROBIN ||
          selectedCalendarType.value === CalendarType.SERVICE ||
          selectedCalendarType.value === CalendarType.PERSONAL) &&
        state.teamMembers?.length === 1
      ) {
        if (hasEmptyKeys(state?.teamMembers[0]?.locationConfigurations)) {
          toast.error('Empty value for meeting location is not allowed')
          throw new Error('Empty value for meeting location is not allowed')
        }
        if (hasDuplicateKeyValue(state.teamMembers[0].locationConfigurations)) {
          toast.error('Duplicate value for same location type is not allowed')
          throw new Error(
            'Duplicate value for same location type is not allowed'
          )
        }
      }

      if (
        state.teamMembers.length > 1 &&
        state.teamMembers.find(item =>
          item.locationConfigurations.find(x => x.kind === 'booker')
        )
      ) {
        toast.error('Ask the Booker is not allowed for multiple team members')
        throw new Error(
          'Ask the Booker is not allowed for multiple team members'
        )
      }

      if (selectedCalendarType.value === CalendarType.CLASS_BOOKING) {
        if (!state.teamMembers?.[0]?.locationConfigurations?.[0]?.location) {
          toast.error('Empty value for meeting location is not allowed')
          throw new Error('Please select a meeting location')
        }
      }
    } catch (error) {
      return null
    }

    return state.teamMembers.map(x => {
      // selectedCalendarType.value === CalendarType.ROUND_ROBIN &&
      //   delete x.meetingLocation
      x?.locationConfigurations?.map((y, l) => {
        if (y?.kind !== 'custom' && y?.alias) {
          delete y.alias
        }
        if (y?.kind === 'booker' && y?.location) {
          y.location = ''
        }
        y.meetingId = y?.kind + '_' + l
      })
      return x
    })
  },
  getEventType() {
    return state.eventType
  },
  shouldAddressEnable(locationConfig: {
    kind: string
    location: string
    position: number
  }) {
    return (
      [
        'zoom_conference',
        'google_conference',
        'booker',
        'ms_teams_conference',
      ].indexOf(locationConfig.kind) === -1
    )
  },
  openAliasField(index: string) {
    isAliasFieldEnabled.value.push(index)
  },
  closeAliasField(index: string) {
    isAliasFieldEnabled.value = isAliasFieldEnabled.value.filter(
      x => x !== index
    )
  },
}

const rules = computed(() => {
  return {
    teamMembers: {
      validator() {
        if (selectedCalendarType.value === CalendarType.EVENT) {
          return true
        }

        if (
          selectedCalendarType.value === CalendarType.CLASS_BOOKING ||
          selectedCalendarType.value === CalendarType.PERSONAL
        ) {
          if (teamMembersWithFullDetails.value.length > 1) {
            return new Error('Only one team member is allowed')
          }
        }

        if (selectedCalendarType.value === CalendarType.COLLECTIVE) {
          if (teamMembersWithFullDetails.value.length < 2) {
            return new Error('At least 2 team members are required')
          }
        }

        if (teamMembersWithFullDetails.value.length === 0) {
          return new Error('At least one team member is required')
        }
        return true
      },
      trigger: ['input', 'blur'],
    },
    meetingLocation: {
      validator() {
        if (selectedCalendarType.value !== CalendarType.CLASS_BOOKING) {
          return true
        }

        if (selectedCalendarType.value === CalendarType.CLASS_BOOKING) {
          if (teamMembersWithFullDetails.value.length === 0) {
            return true
          }

          const meetingLocation =
            teamMembersWithFullDetails.value[0]?.meetingLocation

          if (!meetingLocation) {
            return new Error('Meeting Location is required')
          }
        }
        return true
      },
      trigger: ['input', 'blur'],
    },
  }
})

watch(
  () => state.teamMembers,
  () => {
    setEditCalendarValidationData('teamMembers', state.teamMembers)

    if (state.teamMembers.length === 1) {
      state.teamMembers[0].isPrimary = true
    }
  },
  { immediate: true }
)

watch(
  () => state.eventType,
  () => {
    if (state.eventType === EventType.RoundRobin_OptimizeForEqualDistribution) {
      state.teamMembers = state.teamMembers.map(x => {
        return {
          ...x,
          priority: 0.5,
        }
      })
    }
  }
)

watch(
  () => editingCalendar.value,
  () => {
    methods.loadData()
  }
)

watchEffect(() => {
  AppState.isMultipleLocationEnabled =
    state.teamMembers?.length === 1 &&
    (selectedCalendarType?.value === CalendarType.ROUND_ROBIN ||
      selectedCalendarType?.value === CalendarType.PERSONAL) &&
    state.teamMembers?.[0]?.locationConfigurations.length > 1
})

defineExpose({
  methods,
})
const toggleAdvancedSettingsModal = () => {
  state.showAdvancedSettings = !state.showAdvancedSettings
}

function processTeamMemberInfo(teamMemberDetail, userId) {
  if (!teamMemberDetail) {
    return getDefaultTeamMember(userId)
  }

  const zoomConnection = state.zoomConnections.find(
    connection => connection.ownerId === userId
  )

  const selectedCustomMeetingLocation =
    teamMemberDetail.locationWiseMeetingLocation &&
    teamMemberDetail.locationWiseMeetingLocation[AppState.locationId]
  const selectedIsZoomAdded =
    teamMemberDetail.locationWiseZoomAdded &&
    teamMemberDetail.locationWiseZoomAdded[AppState.locationId]

  return {
    userId,
    priority: 0.5,
    isZoomAdded:
      selectedCalendarType.value === CalendarType.CLASS_BOOKING
        ? 'false'
        : selectedIsZoomAdded || 'false',
    zoomOauthId: zoomConnection?.oauthId || '',
    meetingLocation: selectedCustomMeetingLocation || '',
    selected: true,
    locationConfigurations: [
      {
        kind:
          selectedCalendarType.value === CalendarType.CLASS_BOOKING
            ? 'custom'
            : selectedIsZoomAdded
            ? 'zoom_conference'
            : 'custom',
        location: selectedCustomMeetingLocation || '',
        position: 0,
        zoomOauthId: zoomConnection?.oauthId || '',
      },
    ],
  }
}
</script>

<template>
  <UIForm
    id="create-calendar-form"
    ref="formRef"
    :model="state.formData"
    :rules="rules"
  >
    <UIDivider />

    <UIFormItem
      v-if="selectedCalendarType === CalendarType.ROUND_ROBIN"
      label="Appointment Distribution"
    >
      <div class="flex w-full flex-col space-y-4">
        <div class="flex space-x-4">
          <UIRadio
            id="calendar-opt-avail"
            :checked="
              state.eventType === EventType.RoundRobin_OptimizeForAvailability
            "
            :value="EventType.RoundRobin_OptimizeForAvailability"
            :name="EventType.RoundRobin_OptimizeForAvailability"
            @change="
              state.eventType = EventType.RoundRobin_OptimizeForAvailability
            "
          >
            {{ $t('calendar_advanced.meeting_details.opt_avail') }}
          </UIRadio>

          <UIRadio
            id="calendar-opt-distr"
            :checked="
              state.eventType ===
              EventType.RoundRobin_OptimizeForEqualDistribution
            "
            :value="EventType.RoundRobin_OptimizeForEqualDistribution"
            :name="EventType.RoundRobin_OptimizeForEqualDistribution"
            @change="
              state.eventType =
                EventType.RoundRobin_OptimizeForEqualDistribution
            "
          >
            {{ $t('calendar_advanced.meeting_details.opt_equal') }}
          </UIRadio>
        </div>
      </div>
    </UIFormItem>

    <UIFormItem
      v-if="
        selectedCalendarType === CalendarType.ROUND_ROBIN ||
        selectedCalendarType === CalendarType.CLASS_BOOKING ||
        selectedCalendarType === CalendarType.COLLECTIVE ||
        selectedCalendarType === CalendarType.SERVICE ||
        selectedCalendarType === CalendarType.PERSONAL
      "
      path="teamMembers"
    >
      <div class="flex w-full flex-col">
        <div class="mb-2 flex flex-row justify-between">
          <div>
            {{
              selectedCalendarType === CalendarType.ROUND_ROBIN
                ? $t('calendar_advanced.meeting_details.team_members')
                : $t('calendar_advanced.meeting_details.team_member')
            }}
          </div>
          <div
            v-if="selectedCalendarType === CalendarType.ROUND_ROBIN"
            class="flex cursor-pointer items-center gap-2 self-end rounded-lg px-[12px] py-[6px]"
          >
            <Settings01Icon
              class="mt-[1px] h-4 w-4 cursor-pointer text-sm font-medium text-primary-700"
              @click="toggleAdvancedSettingsModal"
            />
            <p
              class="cursor-pointer text-sm font-medium text-primary-700"
              :placement="'top'"
              role="button"
              @click="toggleAdvancedSettingsModal"
              @keypress="toggleAdvancedSettingsModal"
            >
              {{ $t('create_calendar.advanced_settings') }}
            </p>
          </div>
        </div>
        <UITooltip
          v-if="
            (AppState.editCalendarValidationData.enableRecurring &&
              state.teamMembers.length === 1) ||
            selectedCalendarType === CalendarType.CLASS_BOOKING ||
            selectedCalendarType === CalendarType.PERSONAL ||
            (teamMembersWithFullDetails.length === 1 &&
              teamMembersWithFullDetails[0].locationConfigurations.length > 1)
          "
          :placement="'top'"
        >
          <template #trigger>
            <UISelect
              id="calendar-team-member-select"
              :value="teamMembersWithFullDetails.map(x => x.userId)"
              :multiple="true"
              :filterable="true"
              :options="usersInLocationOptions"
              @change="methods.teamMembersChanged"
            />
          </template>
          <p
            v-if="
              teamMembersWithFullDetails.length === 1 &&
              teamMembersWithFullDetails[0].locationConfigurations.length > 1
            "
            class="max-w-sm"
          >
            {{ t('calendar_advanced.meeting_details.only_one_team_member') }}
          </p>

          <p v-else-if="selectedCalendarType === CalendarType.PERSONAL">
            {{
              t(
                'calendar_advanced.meeting_details.only_one_team_member_personal_calendar'
              )
            }}
          </p>

          <p v-else class="max-w-sm">
            {{
              selectedCalendarType === CalendarType.CLASS_BOOKING
                ? t(
                    'calendar_advanced.meeting_details.team_member_disabled_class_booking_tooltip'
                  )
                : t(
                    'calendar_advanced.meeting_details.team_member_disabled_recurring_tooltip'
                  )
            }}
          </p>
        </UITooltip>
        <UISelect
          v-else
          id="calendar-team-member-select"
          :value="teamMembersWithFullDetails.map(x => x.userId)"
          :multiple="true"
          :filterable="true"
          :options="usersInLocationOptions"
          @change="methods.teamMembersChanged"
        />
      </div>
    </UIFormItem>
  </UIForm>
  <div>
    <div
      v-for="(teamMember, ind) in teamMembersWithFullDetails"
      :key="ind"
      class="flex items-start justify-between py-5"
    >
      <div class="flex items-center space-x-3">
        <div v-if="teamMember.profilePhoto" class="flex items-center space-x-3">
          <img
            :src="teamMember.profilePhoto"
            class="h-9 w-9 rounded-full object-center"
            alt="profile-photo"
          />
        </div>
        <UIAvatar v-else>
          {{ getInitials(teamMember?.name) }}
        </UIAvatar>

        <div :id="teamMember?.userId" name="team-Member-name" class="">
          {{ teamMember.name }}
        </div>
      </div>

      <div
        v-if="
          selectedCalendarType === CalendarType.ROUND_ROBIN ||
          selectedCalendarType === CalendarType.CLASS_BOOKING ||
          selectedCalendarType === CalendarType.SERVICE ||
          selectedCalendarType === CalendarType.PERSONAL
        "
      >
        <div
          v-for="(locationConfig, index) in teamMember.locationConfigurations"
          :key="index"
          class="flex flex-col"
        >
          <div class="flex w-full space-x-4">
            <UISelect
              v-if="
                state.eventType ===
                  EventType.RoundRobin_OptimizeForAvailability &&
                selectedCalendarType !== CalendarType.CLASS_BOOKING &&
                index === 0 &&
                selectedCalendarType !== CalendarType.PERSONAL
              "
              :id="`${teamMember?.userId}-calendar-team-member-priority`"
              :value="teamMember.priority"
              :options="priorityOptions"
              placeholder=""
              class="w-40"
              @change="
                value => methods.priorityChanged(teamMember.userId, value)
              "
            />

            <UISelect
              :id="`${teamMember?.userId}-calendar-team-member-meeting-location-select`"
              :value="locationConfig.kind"
              class="w-40"
              :options="meetingLocationOptions"
              placeholder=""
              @change="
                value =>
                  methods.meetingLocationTypeChangeNew(
                    teamMember.userId,
                    value,
                    index
                  )
              "
            />

            <UIButton
              :id="`${teamMember?.userId}-team-member-delete`"
              class="h-10 w-10 rounded-lg bg-gray-100 text-black"
              :text="true"
              @click="
                () => {
                  methods.closeAliasField(teamMember?.userId + index)
                  if (
                    index === 0 &&
                    teamMember?.locationConfigurations?.length === 1
                  ) {
                    methods.removeTeamMember(teamMember.userId)
                  } else {
                    teamMember.locationConfigurations.splice(index, 1)
                  }
                }
              "
            >
              <Trash03Icon class="w-4" />
            </UIButton>
          </div>

          <div
            v-if="methods.shouldAddressEnable(locationConfig)"
            class="space-y-1"
          >
            <UIFormItem path="meetingLocation" label="">
              <div class="flex w-full flex-col gap-3">
                <div class="w-full">
                  <UIInput
                    v-if="locationConfig.kind !== 'custom'"
                    :id="`${teamMember?.userId}-calendar-team-member-meeting-location-input`"
                    class="-mt-10 w-64"
                    :model-value="
                      teamMember.locationConfigurations[index].location
                    "
                    placeholder="Meeting Location"
                    @update:model-value="(location: string) =>
      methods.meetingLocationChangedNew(teamMember.userId, location, index, locationConfig.kind)
    "
                  />

                  <div v-else class="flex w-full items-center gap-3">
                    <UIInputGroup class="-mt-10 h-full w-64">
                      <UIInput
                        :model-value="
                          teamMember.locationConfigurations[index].location
                        "
                        placeholder="Meeting Location"
                        class="h-full flex-1"
                        @update:model-value="(location: string) =>
      methods.meetingLocationChangedNew(teamMember.userId, location, index, locationConfig.kind)
    "
                      >
                        <template #suffix>
                          <UIDropdownTree
                            :options="meetingLocationRef"
                            :path-separator="'/'"
                            :disable-search="false"
                            :disabled="undefined"
                            :trigger-label="undefined"
                            :debug="undefined"
                            :placement="undefined"
                            :loading="undefined"
                            :remote="undefined"
                            :search-placeholder="''"
                            @on-select="(value: string) => {
    teamMember.locationConfigurations[index].location =
      teamMember.locationConfigurations[index].location +
      ' ' +
      value
  }"
                          >
                            <!-- eslint-disable-next-line vue/no-unused-vars -->
                            <template #trigger="triggerProps">
                              <UIButton
                                id="dd"
                                class="cursor-pointer"
                                quaternary
                              >
                                <Tag01Icon class="h-5 w-5 lg:-mr-2" />
                              </UIButton>
                            </template>
                          </UIDropdownTree>
                        </template>
                      </UIInput>

                      <UIButton
                        v-if="
                          (selectedCalendarType === CalendarType.ROUND_ROBIN ||
                            selectedCalendarType === CalendarType.PERSONAL) &&
                          state.teamMembers.length === 1
                        "
                        class="h-10"
                        :disabled="
                          isAliasFieldEnabled.includes(teamMember.userId) ||
                          teamMember.locationConfigurations[index].alias?.length
                        "
                        @click="
                          methods.openAliasField(teamMember.userId + index)
                        "
                      >
                        <span class="text-primary-600">
                          {{
                            $t(
                              'calendar_advanced.meeting_details.add_display_label'
                            )
                          }}
                        </span>
                      </UIButton>
                    </UIInputGroup>
                  </div>
                </div>
                <div
                  v-if="
                    teamMember.locationConfigurations[index].alias ||
                    isAliasFieldEnabled.includes(teamMember.userId + index)
                  "
                  class="flex w-full gap-3"
                >
                  <UIInput
                    :id="`${teamMember?.userId}-calendar-team-member-meeting-alias-input`"
                    class="w-64"
                    :model-value="
                      teamMember.locationConfigurations[index].alias
                    "
                    :placeholder="
                      $t(
                        'calendar_advanced.meeting_details.enter_display_label'
                      )
                    "
                    @update:model-value="(alias: string) =>
      methods.meetingAliasChanged(teamMember.userId, alias, index)
    "
                  />
                  <UIButton
                    id="remove-location"
                    class="h-10 w-10 rounded-lg bg-gray-100 text-black"
                    :text="true"
                    aria-labelledby="remove-location"
                    @click="
                      methods.closeAliasField(teamMember.userId + index),
                        (teamMember.locationConfigurations[index].alias = '')
                    "
                  >
                    <Trash03Icon class="w-4" />
                  </UIButton>
                </div>
              </div>
            </UIFormItem>
          </div>
          <hr
            :class="
              methods.shouldAddressEnable(locationConfig)
                ? '-mt-2 mb-4'
                : 'mb-4 mt-4'
            "
          />
          <div
            v-if="
              teamMembersWithFullDetails.length === 1 &&
              index === teamMember.locationConfigurations.length - 1 &&
              (selectedCalendarType === CalendarType.ROUND_ROBIN ||
                selectedCalendarType === CalendarType.SERVICE ||
                selectedCalendarType === CalendarType.PERSONAL)
            "
            class="flex w-full items-center justify-end gap-2"
          >
            <UIButton
              :id="'add-location-btn'"
              :type="'tertiary'"
              :size="'medium'"
              :loading="false"
              :ghost="false"
              :quaternary="false"
              :circle="false"
              :text="true"
              :link-gray="undefined"
              :style="{ backgroundColor: '#EFF4FF' }"
              class="self self-end rounded px-4 py-3"
              @click="
                () =>
                  teamMember.locationConfigurations.push({
                    kind: 'custom',
                    location: '',
                  })
              "
            >
              {{ t('calendar_advanced.meeting_details.add_location') }}
            </UIButton>
            <UITooltip>
              <template #trigger>
                <InfoCircleIcon class="h-4 w-4 text-primary-600" />
              </template>
              <p>
                {{
                  t('calendar_advanced.meeting_details.multiple_locations_neo')
                }}
              </p>
            </UITooltip>
          </div>
        </div>
      </div>

      <div v-else-if="selectedCalendarType === CalendarType.COLLECTIVE">
        <div
          v-for="(locationConfig, index) in teamMember.locationConfigurations"
          :key="index"
          class="flex flex-col space-y-5"
        >
          <div class="flex space-x-4">
            <UISelect
              :id="`${teamMember?.userId}-calendar-team-member-meeting-location-select`"
              :value="locationConfig.kind"
              class="w-36"
              :class="{ '!mr-12': teamMember.isPrimary }"
              :options="meetingLocationOptions"
              placeholder=""
              @change="
                value =>
                  methods.meetingLocationTypeChangeNew(
                    teamMember.userId,
                    value,
                    index
                  )
              "
            />
            <div
              v-if="teamMember.isPrimary"
              id="`${teamMember?.userId}-team-member-delete`"
              class="flex items-center rounded-full bg-gray-200 px-3 py-1.5 font-medium text-black"
              :text="true"
            >
              <UserCircleIcon class="mr-2 h-5 w-5" />
              <div class="">Owner</div>
            </div>
            <div v-else class="flex items-center space-x-5">
              <UIButton
                id="`${teamMember?.userId}-team-member-delete`"
                class="ml-7 text-gray-500"
                :text="true"
                @click="() => methods.isPrimaryChanged(teamMember.userId, true)"
              >
                {{ t('calendar_advanced.meeting_details.make_owner') }}
              </UIButton>

              <UIButton
                id="`${teamMember?.userId}-team-member-delete`"
                class="ml-7 text-red-600"
                :class="{
                  invisible: teamMember.isPrimary,
                }"
                :text="true"
                aria-labelledby="remove-location"
                @click="() => methods.removeTeamMember(teamMember.userId)"
              >
                <Trash03Icon class="w-4" />
              </UIButton>
            </div>
          </div>
          <div class="space-y-1">
            <UIFormItem path="meetingLocation" label="">
              <UIInput
                v-if="methods.shouldAddressEnable(locationConfig)"
                :id="`${teamMember?.userId}-calendar-team-member-meeting-location-input`"
                class="-mt-10 w-64"
                :model-value="locationConfig.location"
                placeholder="Meeting Location"
                @update:model-value="
                  value =>
                    methods.meetingLocationChanged(teamMember.userId, value)
                "
              />
            </UIFormItem>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AdvancedCalendarScheduleSettingsModal
    :show="state.showAdvancedSettings"
    :close-modal="toggleAdvancedSettingsModal"
    @on-advanced-scheduling-settings-update="methods.emitAdvancedFormData"
  />
</template>
