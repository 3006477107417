{
  "settings": {
    "calendar": {
      "calendar_settings": "Calendar Settings",
      "select_calendar_type": "Select Calendar Type",
      "add_new_calendar_group": "Add New Calendar Group",
      "add_new_equipment": "Add new Equipment",
      "new_calendar": "New Calendar",
      "calendar_group_description": "Use calendar groups to effectively organize and group multiple calendars together.",
      "group": "Group",
      "manage_your_calendars_and_groups": "Manage your calendars and groups",
      "groups": "Groups",
      "create_group": "Create Group",
      "create_calendar": "Create Calendar",
      "calendar_name": "Calendar Name",
      "group_duration": "Group Duration",
      "calendar_type": "Calendar Type",
      "action": "Action",
      "round_robin": "Round Robin",
      "event": "Event",
      "group_name": "Group Name",
      "room_name": "Room Name",
      "group_description": "Group Description",
      "calendar_url": "Calendar URL",
      "calendars": "Calendars",
      "date_updated": "Date Updated",
      "status": "Status",
      "action_dropdown": "Action Dropdown",
      "calendar_list": "Services Associated to",
      "edit": "Edit",
      "duplicate": "Duplicate",
      "copy_embed_code": "Copy Embed Code",
      "copy_permanent_link": "Copy Permanent Link",
      "copy_new_slug_link": "Copy Scheduling Link",
      "copy_scheduling_link": "Copy Scheduling Link (Legacy Deprecated)",
      "deactivate_calendar": "Deactivate Calendar",
      "move_to_group": "Move to Group",
      "delete_calendar": "Delete Calendar",
      "delete_group": "Delete",
      "delete_this_group": "Delete this group",
      "activate_calendar": "Activate Calendar",
      "duration": "Duration (mins)",
      "all": "All",
      "draft": "Draft",
      "active": "Active",
      "rearrange_calendars": "Rearrange Calendars",
      "deactivate_all_calendars_in_group": "Deactivate all calendars in group",
      "activate_all_calendars_in_group": "Activate all calendars in group",
      "no_groups_found": "Your search did not match any calendar groups.",
      "no_groups_in_location": "Get started using groups by creating new calendar group.",
      "no_calendars_found": "Get started using calendar by creating new calendar.",
      "no_calendars_found_search": "Your search did not match any calendars.",
      "delete_calendar_message": "You're about to delete Calendar. When you hit delete it will also delete all its appointments.",
      "delete_calendar_message_title": "Delete this Calendar?",
      "duplicate_calendar_message": "You're about to duplicate Calendar. This will create a new calendar with the same settings as the selected calendar.",
      "duplicate_calendar_message_title": "Duplicate this Calendar?",
      "no_calendars_found_title": "No Calendars Found",
      "no_groups_in_location_title": "No Groups Found",
      "delete_group_confirmation_title": "Delete this Group?",
      "delete_group_confirmation": "Are you sure you wish to delete the selected group?",
      "delete_group_confirmation_checkbox_title": "Delete associated calendars and appointments",
      "delete_group_confirmation_checkbox_description": "Selecting this option will also delete the calendars in this group, along with all appointments in those calendars.",
      "group_status_message_title": "{status} this Group?",
      "calendar_status_message_title": "{status} this Calendar?",
      "calendar_status_message": "You're about to {status} Calendar",
      "group_status_message": "You're about to {status} Group",
      "coming_soon": "Coming Soon",
      "select": "Select",
      "15mins": "15mins",
      "cancel": "Cancel",
      "save": "Save",
      "create": "Create",
      "select_calendar_group": "Select Calendar Group",
      "move_calendar_confirmation": "Please note that the legacy link would stop working once you proceed with the change. Please confirm by selecting the checkbox.",
      "previously_called_unassigned": " (Previously called as unassigned)",
      "scheduling_type": "Scheduling type",
      "scheduling_type_description": "Choose a scheduling type for your new calendar",
      "service_menu_name": "Service Menu Name",
      "template": "Template",
      "group_url": "Group URL",
      "template_description": "You now have the choice to select either the Classic or Neo templates for the Group view",
      "rooms": "Rooms",
      "rooms_name": "Name",
      "rooms_description": "Description",
      "rooms_modal_description": "Add or edit room",
      "rooms_capacity": "Total Capacity",
      "no_rooms_in_location_title": "No rooms found",
      "no_rooms_in_location": "No rooms found in this location",
      "no_equipments_in_location": "No equipment found in this location",
      "equipments": "Equipment",
      "equipment_name": "Name",
      "equipment_name_search": "Equipment Name",
      "equipment_description": "Description",
      "equipment_modal_description": "Add or edit an equipment",
      "add_equipment": "Add/Edit Equipment",
      "add_room": "Add/Edit Room",
      "equipment_quantity": "Total Quantity",
      "equipment_outOfService": "Out of Service Quantity",
      "no_equipments_in_location_title": "No equipment found",
      "delete_this_equipment": "Delete this equipment",
      "delete_this_rooms": "Delete this room",
      "delete_equipment_confirmation": "Are you sure, you want to delete this equipment? This will be removed from all the linked calendars also.",
      "delete_rooms_confirmation": "Are you sure, you want to delete this room? This will be removed from all the linked calendars also.",
      "add_new_calendar_equipments": "Add new equipment",
      "no_equipments_found": "Your search did not match any equipment.",
      "no_rooms_found": "Your search did not match any rooms.",
      "add_new_calendar_rooms": "Add new room",
      "rooms_disable": "Disable Room",
      "equipments_disable": "Disable Equipment",
      "equipments_activate": "Activate Equipment",
      "rooms_activate": "Activate Room",
      "create_equipments": "Create Equipment",
      "create_rooms": "Create Room",
      "select_calendar_rooms_title": "Select calendar",
      "select_calendar_rooms_description": "You can select multiple calendars to associate with rooms",
      "select_calendar_equipments_title": "Select calendar",
      "select_calendar_equipments_description": "You can select one calendar to associate with the equipment",
      "disable_equipment_title": "{status} equipment",
      "disable_room_title": "{status} room",
      "equipments_status_message": "Are you sure you want to {status} this Equipment?",
      "rooms_status_message": "Are you sure you want to {status} this Room?",
      "calendar_resource_disclaimer": "This might affect the availability on associated service calendars.",
      "equipment_disclaimer": "Note: Enabling the equipment later will automatically reconnect it to calendars it's associated with",
      "room_disclaimer": "Note: Enabling the room later will automatically reconnect it to calendars it's associated with",
      "equipment_tab_name": "Equipment",
      "rooms_tab_name": "Rooms",
      "manage_your_rooms": "Manage rooms for your business here",
      "manage_your_equipments": "Manage equipment for your business here",
      "select_service_calendar_description_equipments": "You can select multiple service calendars to associate with equipment",
      "select_service_calendar_description_rooms": "You can select multiple service calendars to associate with rooms",
      "service_menus": "Service Menu",
      "manage_your_calendars_groups_and_service_menus": "Manage your calendars, groups and service menu",
      "new_service_menu": "Create Service Menu",
      "deactivate_service_menu": "Deactivate Service Menu",
      "delete_service_menu": "Delete this Service Menu",
      "delete_service_menu_confirmation": "Are you sure you want to delete this service menu ?",
      "service_menu_status_message_title": "{status} this Service Menu?",
      "service_menu_status_message": "You're about to {status} Service Menu",
      "no_service_menus_in_location_title": "Create your customized Service Menu",
      "add_new_service_menu": "Add New Service Menu",
      "no_service_menus_in_location": "Start by creating a new service menu and adding Groups to it",
      "no_service_menu_found": "No Service Menu Found",
      "no_service_menu_found_desc": "Your search did not match any service menus.",
      "apply": "Apply",
      "troubleshoot_calendar": "Troubleshoot Calendar",
      "round_robin_booking": "Round Robin",
      "round_robin_description": "Distributes appointments among team members in a rotating order.",
      "round_robin_example": "E.g.: Sales calls, onboarding sessions.",
      "unassigned_booking": "Event Calendar",
      "unassigned_description": "For scheduling physical events with no host association.",
      "unassigned_example": "E.g.: Conferences, public seminars, expos.",
      "class_booking": "Class Booking",
      "class_booking_description": "One host meets with multiple participants.",
      "class_booking_example": "E.g.: Webinars, group training, online classes.",
      "collective_booking": "Collective Booking",
      "collective_booking_description": "Multiple hosts meet with one participant.",
      "collective_booking_example": "E.g.: Panel interviews, committee reviews.",
      "service_booking": "Service Booking",
      "service_booking_description": "Seamless scheduling for service based businesses.",
      "service_booking_example": "E.g.: Spa appointments, repair services, consultations.",
      "personal_booking": "Personal Booking",
      "personal_booking_description": "Schedules one-on-one meetings with a specific team member.",
      "personal_booking_example": "E.g.: Client meetings, private consultations.",
      "personal": "Personal",
      "explore_more_types": "Explore more types",
      "create_group_access_disabled": "You don't have permission to create a group. Contact your admin for access.",
      "create_calendar_access_disabled": "You don't have permission to create a calendar. Contact your admin for access."
    },
    "preferences": {
      "preferences": "Preferences",
      "my_preferences": "My Preference",
      "account_preferences": "Account Preference",
      "user_preferences": "User Preferences",
      "set_preferences": "Set your preferences for your account.",
      "scheduling_options": "Scheduling Options",
      "meeting_location": "Meeting location",
      "zoom": "Zoom",
      "save_preferences": "Save Preferences",
      "in_app_preferences": "In App preferences",
      "set_start_day": "Set preferences like start day for the app",
      "view_options": "View Options",
      "week_starts_on": "Week Starts On",
      "sunday": "Sunday",
      "widget_preferences": "Widget preferences",
      "set_widget_language": "Set preferences like language for widget",
      "language_and_region": "Language and region",
      "language": "Language",
      "english": "English",
      "time_format": "Time Format",
      "time_format_value": "1:30 PM",
      "monday": "Monday",
      "custom_meeting_location": "Custom Meeting Location",
      "allow_time_format_change": "Allow bookers to switch time format on the booking widget"
    },
    "availability": {
      "availability": "Availability",
      "my_availability": "My Availability",
      "account_availability": "Account Availability",
      "timezone": "Timezone",
      "working_hours": "Working Hours",
      "set_recurring_hours": "Set your recurring working hours for meeting",
      "sunday": "Sunday",
      "monday": "Monday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday",
      "thursday": "Thursday",
      "friday": "Friday",
      "saturday": "Saturday",
      "widget_preferences": "Widget preferences",
      "set_widget_language": "Set preferences like language for widget",
      "language_and_region": "Language and region",
      "language": "Language",
      "english": "English",
      "copy_to_all": "Copy to all",
      "save_availability": "Save Availability",
      "add_time": "Add Time",
      "select_time": "Select Time",
      "add_user": "Add User",
      "accessRestricted": {
        "header": "Access Restricted",
        "message": "You're not a part of this account. To get access, add yourself to this account via Agency Settings > Teams > Edit Team Member, and assign the account under 'Roles & Permissions'",
        "contact_admin": "This page is only accessible to admins. Please contact your admin for assistance.",
        "button_text": "Go to Agency Settings"
      }
    },
    "connections": {
      "connections": "Connections",
      "connect": "connect",
      "conferencing": "Conferencing",
      "my_connections": "My Connections",
      "account_connections": "Account Connections",
      "connection_preferences": "Connection Preferences",
      "manage_calendars_conferencing_channels": "Manage Calendars and Conferencing channels",
      "main_integration_calendar": "Main Integration Calendar",
      "event_integration_calendar": "Events created on a system calendar that you are a part of will also be created on this integrated calendar",
      "2way_sync": "2-Way Sync: Create System Events from events booked on the main integrated calendar",
      "calendars": "Calendars",
      "google": "Google",
      "outlook": "Outlook",
      "check_for_conflicts": "Check for Conflicts",
      "choose_accounts_to_connect": "Choose which accounts you wish to connect",
      "payments": "Payments",
      "stripe": "Stripe",
      "ads": "Ads",
      "local_service_ads": "Local Service Ads",
      "fire_workflow": "Fire Workflow Triggers for Events booked via 2-way sync",
      "connect_google_outlook": "Please connect your Google/Outlook/iCloud account to be able to pick primary calendar.",
      "execute_automation": "Execute Automation",
      "save": "Save",
      "lsa": "Local Service Ads",
      "no_write_access": "You do not write access to this calendar",
      "connection_remove_confirmation": "You're about to remove this integration",
      "active": "Active",
      "icloud": {
        "title": "iCloud Integration",
        "description": "Two-factor authentication and an app-specific password are required to connect your account.",
        "email": "iCloud Email Address",
        "password": "iCloud Password",
        "connect": "Connect",
        "cancel": "Cancel"
      }
    }
  },
  "appointment_modal": {
    "select_a_contact": "Select a Contact",
    "start_time": "Start Time",
    "unassigned": "Unassigned",
    "end_time": "End Time",
    "search_by_name_email_phone_or_company": "Search by name, email, phone or company",
    "add_new": "Add New",
    "pick_from_available_contacts": "Or pick from available contacts",
    "book_appointment": "Book Appointment",
    "add_blocked_off_time": "Add Blocked Off Time",
    "recommended_timezones": "Recommended Timezones",
    "all_timezones": "All Timezones",
    "calendar": "Calendar",
    "repeat": "Repeat",
    "date_and_time": "Date and Time",
    "close": "Close",
    "team_member": "Team Member",
    "recurring_booking": "Recurring Booking",
    "one_slot_booking": "One Slot Booking",
    "event_type": "Event Type",
    "occurrences_booked": "occurrences will be booked",
    "repeats": "Repeats",
    "booking_details": "Booking Details",
    "showing_slots_in_this_timezone": "Showing slots in this timezone:",
    "update_contact_tz": "Also Update Contact Timezone to",
    "date": "Date",
    "slot": "Slot",
    "calendar_default": "Calendar Default",
    "custom": "Custom",
    "meeting_notes": "(eg) Meeting notes for our upcoming appointment",
    "out_of_office": "(eg) Out of office for the week!",
    "standard": "Default",
    "appointment_title": "Appointment Title",
    "event_title": "Event Title",
    "appt_with_bob": "(eg) Appointment with Bob",
    "vacation": "(eg) Vacation",
    "show_notes": "Show Notes",
    "hide_notes": "Hide Notes",
    "additional_preferences": "Additional Preferences",
    "meeting_location": "Meeting Location",
    "appointment_status": "Appointment Status",
    "appt_description": "Appointment Description",
    "recurring_details": "Recurring Details",
    "event_description": "Event Description",
    "in_contact_timezone": "In contact's Timezone",
    "vacation_message": "Going on vacation? Taking some time off? Block off time on your calendar to prevent clients from booking appointments existing appointments will still remain on your calendar).",
    "contact": "Contact",
    "delete": "Delete",
    "cancel": "Cancel",
    "back": "Back",
    "pick_available_contacts": "Or pick from available contacts",
    "no_contacts_found": "No contacts found",
    "found": "found",
    "select_one": "Select One",
    "tz_tooltip": "This matters only if you're using Custom Values; such as appointment_start_time appointment_end_time appointment_timezone etc.. in your outbound communication with your clients or customers. And what it simply does is converts the aforesaid details into their local time.",
    "meeting_location_tooltip": "The meeting location will be set as configured in the calendar.",
    "appt_meeting_location_tooltip": "The user can set the meeting location specific to the appointment.",
    "gmeet_tooltip": "Please make sure the respective team member has a Google calendar set as their primary calendar",
    "zoom_tooltip": "Please make sure the respective team member has Zoom integrated",
    "enter_meeting_location": "Enter Meeting Location",
    "save_appointment": "Save Appointment",
    "save_event": "Save Event",
    "search_contacts": "Search by name, email, phone or company",
    "recurring_slots": "{recurringSlotsLength}/{count}",
    "custom_timezone": "Custom Timezone",
    "system_timezone": "System Timezone",
    "contact_timezone": "Contact Timezone",
    "account_timezone": "Account Timezone",
    "an_error_occurred": "An error occurred"
  },
  "popup": {
    "calendar": "Calendar",
    "appointment_owner": "Appointment Owner",
    "source": "Source",
    "booked_by": "Booked By",
    "view_activity_log": "View Activity Log",
    "status": "Status",
    "cancel_appointment": "Cancel Appointment",
    "cancel_all": "Cancel All",
    "google_event": "Google Event",
    "outlook_event": "Outlook Event",
    "blocked_slot": "Blocked-off Slot",
    "appointment": "Appointment",
    "confirmed": "Confirmed",
    "unconfirmed": "Unconfirmed",
    "cancelled": "Cancelled",
    "showed": "Showed",
    "no_show": "No Show",
    "invalid": "Invalid",
    "more": "More",
    "less": "Less",
    "edit": "Edit",
    "reschedule_all": "Reschedule All",
    "guests": "Guest(s)",
    "guest_count": "Guest(s) count"
  },
  "create_calendar": {
    "calendar_name": "Calendar name",
    "calendar_logo": "Calendar logo",
    "select_team_member": "Select team members",
    "eg_outbound_reach": "(eg) Outbound reach",
    "description": "Description",
    "amount": "Amount",
    "description_placeholder": "Write description",
    "meeting_duration": "Meeting duration",
    "seats_per_class": "Seats per class",
    "group": "Group",
    "custom_url": "Custom URL",
    "meeting_invite_title": "Meeting invite title",
    "event_color": "Event color",
    "availability_type": "Availability type",
    "new_calendar": "New Calendar",
    "add_description": "Add description",
    "remove_description": "Remove description",
    "booking_availability": "Booking availability",
    "slug_tooltip": "Customize the direct link to this calendar",
    "meeting_location_tooltip": "Meeting Links are shown in Calendar Events as the 'meeting Location' and allow you to add things like zoom links for the event.",
    "advanced_settings": "Advanced Settings",
    "reschedule_preference": "Reschedule Preference",
    "reschedule_preference_subtext": "If a contact reschedules an appointment:",
    "reassign_through_round_robin": "Reassign through round robin",
    "keep_same_assinged_user": "Keep the same appointment owner",
    "new_appointment_preference": "New Appointment Preference",
    "new_appointment_preference_text": "Always book with the contact's assigned user",
    "new_appointment_preference_subtext": "Book new appointments with the contact's assigned user instead of using round-robin scheduling.",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "service_calendar_name": "Service name",
    "service_logo": "Service logo",
    "service_select_team_member": "Select Staff Member",
    "service_duration": "Service duration",
    "appointment_invite_title": "Appointment invite title",
    "payment_placeholder": "Enter a description that will be displayed to the user, informing them about the payment",
    "deposit_amount": "Deposit Amount",
    "collect_deposit_amount": "Accept partial payment",
    "percentage": "Percentage",
    "total_amount": "Total Amount"
  },
  "calendar_advanced": {
    "general": {
      "quick_tip": "Quick Tip",
      "timezone_change_tip": "Time zones can be changed anytime under Time Zone preferences",
      "save": "Save",
      "close": "Close",
      "meeting_details": "Meeting Details",
      "availability": "Availability",
      "forms_and_payment": "Forms & Payment",
      "notifications_and_additional_options": "Notifications & Additional Options",
      "connections": "Connections",
      "customizations": "Customizations",
      "create": "Create",
      "edit": "Edit",
      "equipments": "Rooms & Equipment",
      "service_details": "Service Details"
    },
    "meeting_details": {
      "meeting_location": "Meeting location",
      "enter_meeting_location": "Enter meeting location",
      "click_to_upload": "Click to upload",
      "square": "Square",
      "circle": "Circle",
      "remove_logo": "Remove logo",
      "meeting_invite_title_tooltip": "This is the title for the Calendar event that shows in Google Calendar, Outlook, Apple Calendar, etc.",
      "event_color_tooltip": "Choose a color for this booking event. Colors get synched to events in Google Calendars.",
      "enter_a_name": "Please enter a name",
      "enter_an_event_title": "Please enter an event title",
      "high_priority": "High Priority",
      "medium_priority": "Medium Priority",
      "low_priority": "Low Priority",
      "custom": "Custom",
      "zoom_not_added": "The user has not connected their Zoom account yet",
      "opt_avail": "Optimize for availability",
      "opt_equal": "Optimize for equal distribution",
      "team_members": "Select team members",
      "team_member": "Select Team member",
      "meeting_location_required": "Meeting location is required",
      "meeting_details": "Meeting Details",
      "meeting_details_description": "These are the details which will be shown in the calendar list page.",
      "or_drag_and_drop": "or drag and drop",
      "service_details": "Service Details",
      "team_member_disabled_recurring_tooltip": "Recurring meetings allow only one team member. To add multiple team members, please disable recurring meetings.",
      "team_member_disabled_class_booking_tooltip": "Only one team member can be added to a class booking calendar",
      "advanced_settings": "Advanced Settings",
      "enable_contact_assigned_to_setting": "To enable this setting, the Form must be first in the widget order. Please rearrange the widget order from the Forms & Payment tab.",
      "reschedule_preference_setting_tooltip": "Choose who the appointment should be assigned to when a contact reschedules from the booking widget.",
      "appointment_preference_tooltip": "Choose whether new appointments should always be booked with the contact's assigned user. If a contact has an assigned user, that user will be given preference; otherwise, it will be a round-robin assignment. Tip: Turn off 'Allow Staff Selection' to prevent bookers from changing the staff member on the booking widget.",
      "add_location": "Add Location",
      "multiple_locations_neo": "Multiple meeting locations are only supported by the Neo Widget with the Default Form.",
      "make_owner": "Make owner",
      "only_one_team_member": "Only one team member is allowed when multiple locations are added.",
      "ask_the_booker_error_message": "Ask the Booker location already exists",
      "enter_location": "Enter Location Value",
      "enter_display_label": "Enter Display Label",
      "add_display_label": "Add Display Label",
      "multiple_location_support_message": "Multiple meeting locations are only supported by the Neo Widget with the Default Form.",
      "only_one_team_member_personal_calendar": "Only one team member is allowed for personal booking ",
      "ms_teams_not_added": "The user has not connected their Microsoft Teams account yet",
      "location_exists_toast": "Meeting location already added"
    },
    "availability": {
      "my_availability": "My availability",
      "set_availability": "Set your availability for the calendar here.",
      "standard": "Standard",
      "custom": "Custom",
      "to": "To",
      "copy_to_all": "Copy to all",
      "add_time": "Add time",
      "recurring_meeting": "Recurring meeting",
      "recurring_info": "Only one user per calendar for recurring booking",
      "repeat": "Repeat",
      "times_to_repeat": "Times to repeat",
      "slots_unavailable": "If slots are unavailable?",
      "overlap_status": "Appointment status for overlapping slots ",
      "meeting_interval": "Meeting interval",
      "meeting_duration": "Meeting duration",
      "minimum_scheduling": "Minimum scheduling notice",
      "date_range": "Date range",
      "maximum_bookings_per_day": "Maximum bookings per day",
      "maximum_bookings_per_slot": "Maximum bookings per slot",
      "maximum_bookings_per_slot_per_user": "Maximum bookings per slot (per user)",
      "seats_per_class": "Seats per class",
      "pre_buffer_time": "Pre buffer time",
      "post_buffer_time": "Post buffer time",
      "pre_buffer_tooltip": "Pre-Buffer is additional time that can be added before an appointment, allowing for extra time to get ready",
      "post_buffer_tooltip": "Post-Buffer is additional time that can be added after an appointment, allowing for extra time to wrap up",
      "buffer_time": "Buffer time",
      "daily": "Daily",
      "weekly": "Weekly",
      "monthly": "Monthly",
      "skip_booking": "Skip booking unavailable slots",
      "continue_booking": "Continue booking",
      "book_next_available": "Book next available slot",
      "confirmed": "Confirmed",
      "unconfirmed": "Unconfirmed",
      "enter_valid_hours": "Please enter valid open hours",
      "enter_valid_avail": "Please enter valid custom availability",
      "availability_type_tooltip": "Choose Standard Availability for regular bookings offered weekly. Choose Custom for bookings only available on specific dates.",
      "meeting_interval_tooltip": "Meeting interval reflects the amount of time the between booking slots that will be shown in the calendar. For a 30 min event that should be available at the top of each hour, the interval would be 1 hr. For a 30 min event that should be available every 30 minutes, the interval would be 30 minutes.",
      "date_range_tooltip": "Events can be scheduled over the next n days.",
      "maximum_bookings_per_day_tooltip": " Maximum number of appointments permitted per day on this calendar.",
      "maximum_bookings_per_slot_tooltip": "Maximum number of appointments permitted per slot for the user (If the number of appointments exceed for the given slot for the user, then it either switches over to the next user in the round-robin queue or marks the slot unavailable for the prospect to book).",
      "add_days": "Add Days",
      "month_on_day": "Monthly on day",
      "month_on_last_day": "Monthly on last day",
      "day": "Day",
      "week": "Week",
      "month": "Month",
      "mins": "Minutes",
      "hours": "Hours",
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "meeting_interval_error": "Meeting interval must be between 5 mins and 12 hours",
      "meeting_duration_error": "Meeting duration must be between 1 min and 12 hours",
      "buffer_time_error": "Buffer time must be less than 12 hours",
      "appointment_interval": "Appointment interval",
      "appointment_duration": "Appointment duration",
      "maximum_appointments_per_day": "Maximum appointments per day",
      "subTitle": "Choose the date to set specific hours",
      "heading_1": "Weekly Available Hours",
      "tooltip_1": "Set your weekly availability schedule. These are your standard hours that will be applied consistently on a weekly basis",
      "heading_2": "When are you available?",
      "unavailable_label": "Unavailable",
      "tooltip_2": "Adjust your schedule for specific dates. Override your regular weekly hours by marking availability or unavailability for selected dates.",
      "date_specific_level": "Add date specific hours",
      "specific_hours_button": "Date Specific Hours",
      "overlap_error": "Time slots cannot overlap",
      "apply": "Apply",
      "copy_times_to": "Copy times to",
      "no_date_specific_heading": "No date specific time added",
      "no_date_specific_description": "You can add/remove specific date and time to your availability",
      "recurring_disabled_conditions_tooltip": "Recurring meetings are disabled. To activate them, ensure that the calendar has only one team member and does not have date-specific hours or partial payment added.",
      "date_specific_hours_disabled_conditions_tooltip": "To include a date-specific hour, please ensure that recurring meetings are turned off.",
      "recurring_disable_event_calendar_conditions": "Recurring meetings are disabled. To activate them ensure that no date-specific hours or partial payment added.",
      "service_duration": "Service duration",
      "service_interval": "Service interval",
      "service_interval_tooltip": "The meeting interval sets how often booking slots appear on the calendar. For example, for a 30 minute event, a 1-hour interval means slots appear every hour.A 30-minute interval means slots appear every 30 minutes.",
      "service_duration_tooltip": "This sets the length of your appointment, determining how long it will last.",
      "minimum_scheduling_tooltip": "This sets the minimum time required to book an appointment via the calendar widget. For example, if set to 4 hours, appointments cannot be booked within 4 hours of their start time; bookings must be made at least 4 hours in advance.",
      "look_busy_title": "Look Busy",
      "look_busy_helper_text": "Hide a percentage of your available appointment slots on the booking widget to appear busier and in high demand.",
      "look_busy_percentage_description": "Hide the number of available slots by x%",
      "maximum_bookings_per_slot_tooltip_personal": "Maximum number of appointments permitted per slot for the user"
    },
    "forms_payment": {
      "forms": "Forms",
      "forms_sub": "Set post-scheduling form preferences.",
      "select_form": "Select form",
      "enable_same_user_assignment": "Assign contacts to their respective calendar team members each time an appointment is booked",
      "sticky_contacts": "Pre-populate fields (sticky contacts)",
      "confirmation_page": "Confirmation page",
      "default": "Default",
      "redirect_url": "Redirect URL",
      "thank_you": "Thank you message",
      "enter_url": "Enter URL",
      "pixel_id": "Facebook pixel ID (optional)",
      "auto_confirm": "Auto-confirm new calendar meetings",
      "configure_payment": "Configure payments options",
      "accept_payments": "Accept payments",
      "test": "Test",
      "live": "Live",
      "enable_guests": "Add Guests",
      "add_guest_tooltip": "Add guest will allow your attendee to add multiple attendees to the same appointment",
      "add_guest_custom_form_tooltip": "Enabling this option will send a system-generated email to guests with a link to the custom form once their appointment is confirmed. To prevent duplicate emails, ensure a separate confirmation email is not already set up for guests. (Verify this in the Notifications tab > Confirmation Notification)",
      "add_guest_same_form_check": "Send same form to collect information via mail to all guests",
      "payment_tooltip": "Payment would only be considered for the main attendee and not for the guests.",
      "default_form": "Default (First name, Last name, Email, Phone, Notes)",
      "select_form_tooltip": "If you would like to collect more information than the standard First Name, Last Name, Email, and Phone from those who book on this calendar, create a custom form in Sites > Forms > Builder then select the form via the dropdown below.",
      "confirmation_page_tooltip": "Once someone makes a booking, you can choose to show them a custom message on the same page (Default) or redirect them to a page of your choice (Redirect).",
      "add_guest_collect_email": "Name and email",
      "add_guest_count_only": "Count only",
      "custom_email_notification": "Send custom form to Guests",
      "consent_label": "Consent checkbox",
      "consent_input_placeholder": "Add Consent text",
      "same_user_assignment_tooltip": "To enable this setting, the Form must be first on the widget. Rearrange the widget to position the Form first to activate this setting.",
      "consent_tooltip": "Toggle this option to allow obtaining consent from contacts when they provide their phone number during the appointment booking process.",
      "stripe_error": "Upto two decimals (example: 25.99)",
      "auto_confirm_service": "Auto-confirm new appointments",
      "service_form_message": "If a contact is booking multiple services, they will only see the default form in the service menu",
      "deposit_amount_error": "Deposit amount must be less than total amount.",
      "deposit_percentage_error": "Deposit percentage must be less than 100%.",
      "partial_payment_tooltip": "Enable partial payments to collect deposit amount from customers during the appointment booking process.",
      "partial_payment_enable_error": "The total amount entered must be greater than 0 to enable partial payments.",
      "payment_disable_text_integration": "Integrate a payment provider under Payments > Integrations to start accepting payments",
      "payment_disable_text_recurring": "Accept payment option is unavailable for recurring meetings. Turn off recurring to enable payments.",
      "date_and_time_selector": "Date & Time Selector",
      "form_selector": "Form",
      "order_step": "Step",
      "widget_order_label": "Widget order",
      "widget_order_tool_tip": "Rearrange the 'Date & Time Selector' and 'Form' order on the calendar widget. Simply drag and reorder according to your preference.",
      "widget_re_order_tooltip": "Reordering is only available with the Neo widget style. Switch to Neo to enable reordering.",
      "widget_re_order_disabled_tooltip": "To rearrange the widget order, please disable 'New Appointment Preference (Always book with Contact's assigned user)' from Meeting Details > Team Members > Advanced Setting section.",
      "payment_description_error": "Maximum character limit of 1000 reached",
      "widget_multiple_location_disabled_tooltip": "The Classic Widget does not support multiple meeting locations. Please remove any additional locations to use the Classic Widget",
      "recurring_payment_label": "Recurring appointments payment",
      "first_appointment_only": "First appointment only",
      "all_appointments": "All appointments",
      "enable_mandatory_guest": "Take one mandatory guest information on the booking widget",
      "enable_mandatory_guest_count": "Take mandatory guest count on the booking widget"
    },
    "notifications": {
      "notifications": "Notifications & Additional Options",
      "notifications_sub": "Configure notification and additional options",
      "select_notification_type": "Select the type of notification",
      "notification_person": "Who should receive this notification?",
      "contact": "Contact",
      "guest": "Guest",
      "assigned_user": "Assigned user",
      "emails": "Emails",
      "alert_email_address": "Alert email address",
      "ack_email": "Acknowledgement email",
      "send_google_invites": "Allow Google calendar to send invitation or update emails to attendees.",
      "send_google_outlook_invites": "Allow Google / Outlook Calendar to send invitation & update emails to attendees.",
      "assign_user_to_contact": "Assign contacts to their respective calendar team members each time an appointment is booked",
      "skip_assigning_user_to_contact": "Skip assigning contact if the contact has already an assigned user",
      "assigned_staff": "Assigned staff",
      "additional_emails": "Additional emails",
      "assign_user_to_staff": "Assign contacts to their respective service staff members each time an appointment is booked",
      "allow_cancellation": " Allow Cancellation of Meeting",
      "allow_reschedule": "Allow Rescheduling of Meeting",
      "allow_cancellation_tooltip": "When enabled, a cancellation link will be added to the additional notes section and included in the calendar invite. This link will expire as configured before the meeting start time, preventing the booker from canceling the meeting.",
      "allow_reschedule_tooltip": " When enabled, a rescheduling link will be added to the additional notes section and included in the calendar invite. This link will expire as configured before the meeting start time, preventing the booker from rescheduling the meeting.",
      "cancellation_link_expiry": "Cancellation link will expire",
      "reschedule_link_expiry": "Rescheduling link will expire",
      "before_meeting": "before the meeting",
      "cancellation_and_reschedule_policy": "Cancellation and Reschedule Policy:"
    },
    "connections": {
      "connections_heading": "External Calendar Sync",
      "connections_sub": "Sync events with external calendar",
      "link_to_calendar": "Link to calendar",
      "sync_option": "Sync option",
      "execute_automation": "Execute automation",
      "fire_workflow": "Fire Workflow Triggers for Events booked via 2-way sync",
      "reserve_with_google": "Reserve with Google",
      "connect_lsa": "Connect with your local services Ads account",
      "reserve_error": "Currently Reserve with Google is supported only for \"Home Services\", \"Real Estate\", \"Legal Services\" (Local Service providers) Company Niche in United States.",
      "one_way": "One Way",
      "two_way": "Two Way",
      "smart": "Smart",
      "confirm_remove": "Are you sure you want to remove Google calendar?",
      "read_only_access": "You do not have write access to this calendar. Please select a different calendar.",
      "reserve_with_google_desc": "This calendar service wil be listed in reserve with Google only on successful integration.",
      "check_integration": "Check your integration status",
      "setup_external": "Setup your external calendar",
      "reserve_price_decimal_error": "Decimal price is not supported",
      "reserve_price_max_error": "Price supported less than 1000"
    },
    "extras": {
      "extras": "Customizations",
      "extras_sub": "Set widget style and other preferences.",
      "calendar_widget_style": "Calendar widget style",
      "neo": "Neo",
      "classic": "Classic",
      "insert_custom_code": "Insert custom code",
      "show_seats_per_slot": "Show seats per slot",
      "allow_reschedule_meeting": "Allow reschedule meeting",
      "allow_cancelling_meeting": "Allow cancelling meeting",
      "additional_notes": "Additional notes",
      "cancellation_and_reschedule": "Cancellation and reschedule:",
      "allow_staff": "Allow staff selection during booking",
      "code_block_tooltip": "Third-party scripts inside the HTML block can provide powerful functionality, but they also bring risks to privacy, security,performance and page behavior. Therefore, we recommend that you to review the contents of this script before saving it to the calendar.",
      "calendar_image": "Calendar Cover Image",
      "remove_image": "Remove image",
      "calendar_image_info": "The uploaded image will be visible within the Group View for Neo template and won't appear on the individual calendar link",
      "get_image_from_unsplash": "Enhance your visuals with",
      "unsplash": "Unsplash",
      "service_additional_preferences": "Configure Additional Preferences",
      "service_image": "Service Cover Image",
      "service_image_description": "Select an image which would be shown on the services menu for this service",
      "allow_reschedule_appointment": "Allow reschedule appointment",
      "allow_cancelling_appointment": "Allow cancelling appointment",
      "custom_code_placeholder": "Please input custom code here",
      "calendar_widget_style_desc": "Choose between our classic or the sleek Neo widget",
      "seats_per_slot": "Seats per slot",
      "show_staff_selection": "Staff Selection",
      "seats_per_slot_desc": " Enable bookers to view the number of available seats for each time slot directly on the calendar widget.",
      "show_staff_selection_desc": "Enable bookers to choose a staff member directly from the calendar widget during appointment booking. Only works with Neo widget",
      "widget_re_order_tooltip": "The Classic widget style does not allow widget order changes. Ensure the Date Time Selector is first and the Form is second before selecting the Classic style.",
      "show_staff_selection_desc_service": "Enable bookers to choose a staff member directly from the calendar widget during appointment booking."
    },
    "roomsAndEquipments": {
      "title": "Select Rooms and Equipment",
      "description": "Select from a list of rooms and equipment what will be booked along with this calendar",
      "rooms": "Select Rooms",
      "equipments": "Select Equipment"
    }
  },
  "activity_log": {
    "activity_log": "Activity Log",
    "date": "Date",
    "filter_by": "Filter By",
    "calendar_update": "Calendar Update",
    "start_time_update": "Start Time Update",
    "end_time_update": "End Time Update",
    "title_update": "Title Update",
    "notes_update": "Notes Update",
    "appointment_status_update": "Appointment Status Update",
    "meeting_location_update": "Meeting Location Update",
    "google_sync_update": "Google Sync Update",
    "outlook_sync_update": "Outlook Sync Update",
    "assigned_user_update": "Assigned User Update",
    "appointment_status": "Appointment Status",
    "assigned_user": "Assigned User",
    "uploaded_to": "Uploaded to",
    "cancelled": "Cancelled",
    "edited": "edited",
    "other_edits": "Other edits",
    "view_details": "View Details",
    "all": "All"
  },
  "appointment_list": {
    "group_all": "ALL",
    "group_primary": "PRIMARY",
    "group_user": "USERS",
    "group_team": "GROUPS",
    "group_calendar": "CALENDARS",
    "all_appointments": "All Appointments",
    "my_calendar": "My Calendar",
    "status_all": "All",
    "status_confirmed": "Confirmed",
    "status_new": "New (Action Required)",
    "status_show": "Showed",
    "status_no_show": "No Show",
    "status_cancelled": "Cancelled",
    "status_invalid": "Invalid",
    "filter_date_added_desc": "Date Added (Desc)",
    "filter_start_added_desc": "Start Date (Desc)",
    "filter_start_added_asc": "Start Date Ascending From Today",
    "button_new_appointment": "New Appointment",
    "name": "Name",
    "title": "Title",
    "requested_time": "Requested Time",
    "date_added": "Date Added",
    "calendar": "Calendar",
    "appointment_owner": "Appointment Owner",
    "notes": "Notes",
    "status": "Status",
    "action_view_detail": "View Details",
    "action_view_consent": "View Consent",
    "action_view_notes": "View Notes",
    "action_edit": "Edit",
    "action_delete": "Delete",
    "action_reschedule": "Reschedule",
    "page_previous": "Previous",
    "page_next": "Next",
    "appointment_notes": "Appointment Notes",
    "close": "Close",
    "confirm_delete_header": "Delete Appointment",
    "confirm_delete_message": "Are you sure you want to delete this appointment? This action cannot be undone!",
    "confirm_delete_button": "Delete",
    "confirm_update_header": "Update Appointment Status",
    "confirm_update_confirmed_message": "Are you sure you want to mark this appointment as confirmed?",
    "confirm_update_invalid_message": "Are you sure you want to mark this appointment as invalid?",
    "confirm_update_showed_message": "Are you sure you want to mark this appointment as showed?",
    "confirm_update_noshow_message": "Are you sure you want to mark this appointment as no show?",
    "confirm_update_cancelled_message": "Are you sure you want to mark this appointment as cancelled?",
    "confirm_update_button": "Update",
    "empty_state_title": "No appointments found",
    "empty_state_description": "No appointments found",
    "disable_menu_warning": "Currently, you don't have permission to view or edit the appointment details, as the associated contact isn't assigned to you. Kindly get in touch with your admin for further assistance.",
    "no_contact_warning": "You're unable to access contact information at the moment. This might be because the contact isn't assigned to you. Please reach out to your admin for assistance.",
    "consent_label": "Consent",
    "ip_address": "IP",
    "youre_viewing_in": "Currently viewing in",
    "appointment_delete_msg": "<b>Please Note:</b> The appointment deleted from here will not be deleted from calendly.",
    "rescheduled": "Rescheduled"
  },
  "add_guest": {
    "guest_label": "Guest(s)",
    "search_placeholder": "Search from contacts",
    "add_new": "Add New",
    "save": "Save",
    "close": "Close",
    "name": "Name",
    "phone": "Phone",
    "email": "Email",
    "guest_label_count": "Guest(s) count",
    "guest_count_error": "Guest count should be between 1 and 100"
  },
  "languages": {
    "danish": "Danish",
    "dutch": "Dutch",
    "english": "English",
    "finnish": "Finnish",
    "french": "French",
    "french_canada": "French (Canada)",
    "german": "German",
    "hungarian": "Hungarian",
    "italian": "Italian",
    "norwegian": "Norwegian",
    "polish": "Polish",
    "portuguese_brazil": "Portuguese (Brazil)",
    "portuguese_portugal": "Portuguese (Portugal)",
    "spanish": "Spanish",
    "swedish": "Swedish",
    "turkish": "Turkish"
  },
  "service_menu_advanced": {
    "new_service_menu_details_header": "Service Menu Details",
    "select_groups_header": "Select Services",
    "arrange_groups_header": "Arrange your services",
    "new_service_menu": "New Service Menu",
    "service_menu_sub_title": "Create a personalized service menu for your customers in just a few clicks",
    "quick_tip": "You can add groups to service menu",
    "service_menu_name": "Service Menu name",
    "service_menu_description": "Description",
    "service_menu_slug": "Service URL",
    "select_groups_calendars": "Select groups and calendars",
    "select_groups_calendars_desc": "Add Services to your service menu",
    "select_all": "Select All",
    "arrange_groups": "Arrange order of services",
    "arrange_groups_desc": "Determine the sequence of services on the service menu",
    "arrange_services_quicktip": "To rearrange calendars within group, go to Groups > Options > Rearrange Calendars.",
    "enable_add_guest_desc": "Allows customers to include additional guests when making a booking.",
    "enable_multiple_service_desc": "Allows customers to book multiple services in a single appointment.",
    "enable_staff_selection_desc": "Allows customers to select preferred staff members when making a booking.",
    "enable_add_guest": "Enable Add Guests",
    "enable_multiple_service": "Enable Multiple Service Selection",
    "enable_staff_selection": "Enable Staff Selection",
    "additional_options": "Additional Options",
    "code_block_tooltip": "Third-party scripts inside the HTML block can provide powerful functionality, but they also bring risks to privacy, security,performance and page behavior. Therefore, we recommend that you to review the contents of this script before saving it to the service menu.",
    "custom_code_placeholder": "Please input custom code here",
    "insert_custom_code": "Insert custom code",
    "no_service": "No service found",
    "no_service_desc": " No services found. Create services and add them to groups to create a service menu.",
    "learn_more": "Learn More",
    "no_service_selected": "No service selected",
    "create_service_calendars": "Create service calendars",
    "alert_description": " In order to create service menu, you need to create service calendars and add them to groups",
    "dont_show_again": "Don’t show this again",
    "service_name_required": "Service Menu name is required",
    "slug_already_taken": "This slug is already taken",
    "slug_required": "Slug is required",
    "please_enter_name": "Please enter a name",
    "service_name_placeholder": "Main Menu",
    "consent_label": "Consent checkbox",
    "consent_input_placeholder": "Add Consent text",
    "consent_tooltip": "Toggle this option to allow obtaining consent from contacts when they provide their phone number during the appointment booking process."
  },
  "widget_configuration": {
    "customise_calendar_widget": "Customize calendar widget",
    "works_with_neo_widget": "Only works with Neo widget",
    "customise_widget_desc": "Customize widget appearance: primary color, background color, and button text.",
    "learn_more": "Learn More",
    "primary_settings": "Primary settings",
    "primary_settings_desc": "Configure the primary color, background color, and other widget preferences to tailor your calendar widget",
    "primary_color": "Primary color",
    "primary_color_desc": "Define the color for buttons, dates, time slots, and actions",
    "background_color": "Background color",
    "background_color_desc": "Set the background color that serves as the backdrop for both date-time selector and form.",
    "button_text": "Button Text",
    "button_text_desc": "Customize the text displayed on the button when booking an appointment",
    "calendar_title": "Calendar Title",
    "calendar_title_desc": "The name of your calendar",
    "calendar_description": "Calendar Description",
    "calendar_description_desc": "The entered description",
    "calendar_details": "Calendar Details",
    "calendar_details_desc": "Includes information such as duration, date & time, recurring details and timezone",
    "reset_to_default": "Reset to default",
    "preview_widget": "Preview Widget"
  },
  "eventNotification": {
    "customFields": "Custom Fields",
    "customValues": "Custom Values",
    "list": {
      "booked": {
        "title": "Appointment Booked (Status: Unconfirmed)",
        "note": "This notification is sent when a new appointment is created with Unconfirmed status.",
        "subTitle": "Notifies when an appointment is booked with an unconfirmed status"
      },
      "confirmation": {
        "title": "Appointment Booked (Status: Confirmed)",
        "note": "This notification is sent when an appointment is created with or updated to the Confirmed status.",
        "subTitle": "Notifies when an appointment is successfully confirmed"
      },
      "cancellation": {
        "title": "Cancellation",
        "note": "This notification is sent when an appointment is cancelled, marked as no-show, or marked as invalid.",
        "subTitle": "Alerts when an appointment is canceled"
      },
      "reschedule": {
        "title": "Reschedule",
        "note": "This notification is sent when an appointment is rescheduled. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle": "Notifies when an appointment is rescheduled"
      },
      "reminder": {
        "title": "Reminder",
        "note": "This notification is sent as a reminder before the appointment. It will only be triggered if the appointment is in Unconfirmed or Confirmed status.",
        "subTitle": "Sends a reminder before the appointment"
      },
      "followup": {
        "title": "Follow-Up",
        "note": "This notification is sent after the appointment as a follow-up. It will only be triggered if the appointment is in Unconfirmed, Confirmed, or Showed status.",
        "subTitle": "Sends a follow-up message after the appointment is completed"
      }
    },
    "saveToastMsg": "Settings Saved!",
    "saveToastMsgDesc": "Your changes have been successfully saved",
    "enableSuccess": "Notifications Enabled!",
    "disableSuccess": "Notifications Disabled!",
    "tooltip": "This notification has been disabled. Please enable it to make any changes.",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "statusLabel": "Status Labels",
    "email": {
      "channelName": "Email",
      "noneTemplate": "None",
      "notificationLabel": "Who should receive this notification?",
      "sendButtonLabel": "Send Test Email",
      "testEmailLabel": "Test Email",
      "testEmailPlaceholder": "Enter the recipient's email address for testing",
      "body": "Email Body",
      "bodyPlaceholder": "Type your message here",
      "subject": "Subject",
      "subjectPlaceholder": "Enter the subject of the email",
      "templateLabel": "Email Template",
      "templatePlaceholder": "Select an email template or start from scratch",
      "fromNamePlaceholder": "Enter name",
      "fromNameLabel": "From Name",
      "fromAddressLabel": "From Address",
      "alertEmailAddress": "Enter Email IDs (separated by commas)",
      "addMore": "Add more",
      "afterAppointmentEnds": "after the appointment ends",
      "beforeAppointmentStarts": "before the appointment starts",
      "durationLabel": "When would you like to trigger the notification?",
      "disableEmailNotification": "Disable Email Notifications",
      "enableEmailNotification": "Enable Email Notifications",
      "enableSuccess": "Email Notification Enabled!",
      "enableSuccessDesc": "You will now start receiving this notification",
      "disableSuccess": "Email Notification Disabled!",
      "disableSuccessDesc": "You will no longer receive this notification"
    },
    "inApp": {
      "channelName": "In-app",
      "title": "Notification Settings",
      "enableInAppNotification": "Enable In-App Notification",
      "disableInAppNotification": "Disable In-App Notification",
      "selectedUser": {
        "label": "Send to",
        "helperText": "This internal notification is sent via the web and mobile apps",
        "placeholder": "Select User(s)"
      },
      "enableSuccess": "In-App Notifications Enabled!",
      "enableSuccessDesc": "You will now start receiving this notification",
      "disableSuccess": "In-App Notifications Disabled!",
      "disableSuccessDesc": "You will no longer receive this notification"
    }
  },
  "emailTemplate": {
    "select_editing_experience": "Select your Editing Experience",
    "select_editing_experience_desc": "Choose to Build using our Visual Drag-and-Drop Design Editor or Powerful Code Editor",
    "design_editor": "Design Editor",
    "design_editor_desc": "Visual, Drag & Drop Editing.",
    "code_editor": "Code Editor",
    "code_editor_desc": "Feature-Rich HTML Editing.",
    "text_editor": "Plain Text Editor",
    "text_editor_desc": "Basic, Clean, No-Frills Text Editing.",
    "email_text_block": "Email can also be created using Text Block. ",
    "select": "Select",
    "new_template": "New Template",
    "email_block": "Email can also be created using Text Block."
  }
}
