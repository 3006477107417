<script setup lang="ts">
import Location from '@/models/location'
import AppState, {
  fetchCalendarLocationSettings,
  fetchLocationDetails,
} from '@/states/app'
import {
  UIButton,
  UIForm,
  UIFormItem,
  UISelect,
  UISpin,
  UISwitch,
  UICheckbox,
} from '@gohighlevel/ghl-ui'
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarLocationSettingsService } from '../../class/services/CalendarLocationSettingsService'
import CalendarLocationSettings from '../../models/calendar_location_settings'
const { t } = useI18n()

const savePreferences = async () => {
  isSaving.value = true
  await axios.put('/locations/' + AppState.locationId, {
    startDayOfWeek: formValue.value.startDayOfWeek,
  })

  await axios.put('/locations/' + AppState.locationId, {
    appStartDayOfWeek: formValue.value.appStartDayOfWeek,
    startDayOfWeek: formValue.value.startDayOfWeek,
    language: formValue.value.selectedLanguage,
    use24hFormat: formValue.value.use24HourFormat === 'true',
  })
  if (showLocationSettings.value) {
    const featureFlagData = featureFlags.value.reduce((acc, flag) => {
      acc[flag.key] = flag.value
      return acc
    }, {})
    await CalendarLocationSettingsService.updateLocationSettings(
      AppState.locationId,
      {
        featureFlags: featureFlagData,
        allowTimeFormatChange: calendarLocationSettings.value.allowTimeFormatChange,
        locationId: AppState.locationId,
      }
    )
  }
  isSaving.value = false
  await fetchCalendarLocationSettings()
}

const startDayOfWeekOptions = computed(() => {
  return [
    {
      label: t('settings.availability.saturday'),
      value: '6',
    },
    {
      label: t('settings.availability.sunday'),
      value: '0',
    },
    {
      label: t('settings.availability.monday'),
      value: '1',
    },
  ]
})

const languageOptions = computed(() => {
  return [
    { value: '', label: t('languages.english') },
    { value: 'da', label: t('languages.danish') },
    { value: 'nl', label: t('languages.dutch') },
    { value: 'fi', label: t('languages.finnish') },
    { value: 'fr', label: t('languages.french') },
    { value: 'fr-ca', label: t('languages.french_canada') },
    { value: 'de', label: t('languages.german') },
    {
      value: 'hu',
      label: `${t('languages.hungarian')} (${t(
        'settings.calendar.coming_soon'
      )})`,
    },
    { value: 'it', label: t('languages.italian') },
    { value: 'no', label: t('languages.norwegian') },
    {
      value: 'pl',
      label: `${t('languages.polish')} (${t('settings.calendar.coming_soon')})`,
    },
    { value: 'pt-br', label: t('languages.portuguese_brazil') },
    { value: 'pt', label: t('languages.portuguese_portugal') },
    { value: 'es', label: t('languages.spanish') },
    { value: 'sv', label: t('languages.swedish')},
  ]
})

const timeFormatOptions = computed(() => {
  return [
    {
      label: '1:30 PM',
      value: 'false',
    },
    {
      label: '13:30',
      value: 'true',
    },
  ]
})

const currentLocationDetail = computed(() => {
  return AppState?.currentLocationDetail as Location
})

const calendarLocationSettings = computed(() => {
  return AppState?.calendarLocationDetails as CalendarLocationSettings
})

onMounted(async () => {
  isLocationDataLoading.value = true
  await fetchLocationDetails(AppState.locationId)
  if (showLocationSettings.value) {
    await fetchCalendarLocationSettings()
  }
  isLocationDataLoading.value = false
})

watch(
  () => currentLocationDetail.value,
  () => {
    if (currentLocationDetail.value) {
      formValue.value.startDayOfWeek = String(
        currentLocationDetail.value.startDayOfWeek || '6'
      )
      formValue.value.appStartDayOfWeek = String(
        currentLocationDetail.value.appStartDayOfWeek || '6'
      )
      formValue.value.selectedLanguage =
        currentLocationDetail.value.language || ''
      formValue.value.use24HourFormat = String(
        currentLocationDetail.value.use24HourFormat || 'false'
      )
    }
  }
)

watch(
  () => calendarLocationSettings.value,
  () => {
    if (calendarLocationSettings.value) {
      Object.keys(calendarLocationSettings.value.featureFlags).forEach(key => {
        const flag = featureFlags.value.find(f => f.key === key)
        if (flag) {
          flag.value = calendarLocationSettings.value.featureFlags[key]
        }
      })
    }
  }
)

const formValue = ref({
  meetingLocationType: 'custom',
  selectedLanguage: '',
  startDayOfWeek: '6',
  appStartDayOfWeek: '6',
  use24HourFormat: 'false',
})

const featureFlags = ref([
  {
    key: 'isServiceMenuEnabled',
    label: 'Service Menu',
    hidden: AppState.user?.type !== 'agency' && AppState.user?.role !== 'admin',
    disabled: false,
    value: false,
  },
  {
    key: 'isRoomEnabled',
    label: 'Rooms',
    hidden: AppState.user?.type !== 'agency' && AppState.user?.role !== 'admin',
    disabled: false,
    value: false,
  },
  {
    key: 'isEquipmentEnabled',
    label: 'Equipments',
    hidden: AppState.user?.type !== 'agency' && AppState.user?.role !== 'admin',
    disabled: false,
    value: false,
  },
])

const isSaving = ref(false)
const isLocationDataLoading = ref(false)
const showLocationSettings = computed(
  () => AppState.user?.type === 'agency' && AppState.user?.role === 'admin'
)
</script>
<template>
  <div class="max-w-4xl">
    <div class="flex flex-col">
      <div class="mb-4 space-y-1">
        <div class="text-lg font-medium text-gray-900">
          {{ $t('settings.preferences.in_app_preferences') }}
        </div>
        <div class="text-sm text-gray-500">
          {{ $t('settings.preferences.set_start_day') }}
        </div>
      </div>
      <div
        class="grid grid-cols-3 items-baseline space-x-20 border-b-2 border-t-2 border-gray-200 py-6"
      >
        <div class="">
          {{ $t('settings.preferences.view_options') }}
        </div>
        <div class="flex flex-col">
          <UISpin v-if="isLocationDataLoading" :show="true" />
          <UIForm v-else id="" style="width: 400px">
            <UIFormItem :label="$t('settings.preferences.week_starts_on')">
              <UISelect
                id=""
                v-model:value="formValue.appStartDayOfWeek"
                :options="startDayOfWeekOptions"
              />
            </UIFormItem>
          </UIForm>
        </div>
      </div>

      <div
        v-if="showLocationSettings"
        class="grid grid-cols-3 items-baseline space-x-20 border-b-2 border-gray-200 py-6"
      >
        <div class="">Services</div>
        <div class="flex flex-col">
          <UISpin v-if="isLocationDataLoading" :show="true" />
          <div v-else class="flex flex-col items-start" style="width: 400px">
            <div
              v-for="(flag, index) in featureFlags"
              :key="index"
              class="mb-2 flex flex-col items-center"
            >
              <div v-if="!flag.hidden" class="flex flex-row items-start gap-1">
                <div class="w-[10rem] pr-2 font-normal">
                  {{ flag.label }}
                </div>
                <div :id="`feature-toogle${index}`">
                  <UISwitch
                    v-model:value="flag.value"
                    aria-label="feature-toogle"
                    size="small"
                    :disabled="flag.disabled"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 flex flex-col">
      <div class="mb-4 space-y-1">
        <div class="text-lg font-medium text-gray-900">
          {{ $t('settings.preferences.widget_preferences') }}
        </div>
        <div class="text-sm text-gray-500">
          {{ $t('settings.preferences.set_widget_language') }}
        </div>
      </div>

      <div
        class="grid grid-cols-3 items-baseline space-x-20 border-t-2 border-gray-200 py-6"
      >
        <div class="col-span-1">
          {{ $t('settings.preferences.language_and_region') }}
        </div>
        <div class="col-span-2 flex flex-col">
          <UISpin v-if="isLocationDataLoading" :show="true" />
          <UIForm v-else id="" style="width: 400px">
            <UIFormItem :label="$t('settings.preferences.language')">
              <UISelect
                id=""
                filterable="true"
                v-model:value="formValue.selectedLanguage"
                :options="languageOptions"
              />
            </UIFormItem>

            <UIFormItem :label="$t('settings.preferences.time_format')">
              <UISelect
                id=""
                v-model:value="formValue.use24HourFormat"
                :options="timeFormatOptions"
                :loading="isLocationDataLoading"
              />
            </UIFormItem>
            <UICheckbox
                id="calendar-allow-time-format-change"
                v-model:checked="calendarLocationSettings.allowTimeFormatChange"
              > {{ $t('settings.preferences.allow_time_format_change')
                }}
              </UICheckbox>
          </UIForm>
        </div>
      </div>

      <div
        class="grid grid-cols-3 items-baseline space-x-20 border-b-2 border-t-2 py-6"
      >
        <div class="">
          {{ $t('settings.preferences.view_options') }}
        </div>
        <div class="flex flex-col">
          <UISpin v-if="isLocationDataLoading" :show="true" />
          <UIForm v-else id="" style="width: 400px">
            <UIFormItem :label="$t('settings.preferences.week_starts_on')">
              <UISelect
                id=""
                v-model:value="formValue.startDayOfWeek"
                :options="startDayOfWeekOptions"
              />
            </UIFormItem>
          </UIForm>
        </div>
      </div>
    </div>

    <UIButton
      class="float-right mb-2 mt-6"
      type="primary"
      :loading="isSaving"
      :disabled="AppState.user?.role === 'user'"
      @click="savePreferences"
    >
      {{ $t('settings.preferences.save_preferences') }}
    </UIButton>
  </div>
</template>
