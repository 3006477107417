<script setup lang="ts">
import { CalendarList } from '@/class/pages/CalendarList'
import { CalendarService } from '@/class/services/CalendarService'
import SelectMoveTeamModal from '@/components/Settings/SelectMoveTeamModal.vue'
import DeleteConfirmation from '@/components/common/DeleteConfirmation.vue'
import DuplicateConfirmation from '@/components/common/DuplicateConfirmation.vue'
import GeneralConfirmation from '@/components/common/GeneralConfirmation.vue'
import Calendar, { CalendarType, WidgetType } from '@/models/calendar'
import AppState, {
  fetchUserDetails,
  getCalendarsInLocation,
  getEquipmentsInLocation,
  getRoomsInLocation,
  getTargetTimezone,
  getTeamsInLocation,
  isCreateCalendarDisabled,
  isPermissionsEnabled,
  updateCalendarStore,
} from '@/states/app'
import { toggleAdvancedView } from '@/utils/commonModalFunctions'
import {
  copyToClipboard,
  generateSlug,
  shouldShowLegacyLink,
} from '@/utils/generalFunctions'
import {
  ArrowRightIcon,
  CalendarCheck01Icon,
  Copy04Icon,
  Copy07Icon,
  Edit02Icon,
  FolderIcon,
  PlusIcon,
  Settings01Icon,
  Share07Icon,
  Toggle03LeftIcon,
  Trash03Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIActionDropdown,
  UIButton,
  UIEmpty,
  UITable,
  UITextSmNormal,
  renderIcon,
  useNotification,
} from '@gohighlevel/ghl-ui'
import moment from 'moment-timezone'
import { h, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarResourceService } from '../../class/services/CalendarResourceService'
import { CALENDAR_RESOURCE_TYPES } from '../../models/calendar_resource'
import { CalendarScopes } from '../../types'
import CalendarShareModal from './CalendarShareModal.vue'

const { t } = useI18n()

const props = defineProps({
  openCalendarTypeModal: {
    type: Function,
    required: true,
  },
})

const showSelectModal = ref(false)
const calendarRef = ref()

const targetTimezone = getTargetTimezone()

const tableActionsOptionsForActiveCalendars = [
  {
    label: t('settings.calendar.edit'),
    key: 'edit',
    icon: renderIcon(Edit02Icon),
  },
  {
    label: t('settings.calendar.duplicate'),
    key: 'duplicate',
    // disabled: true,
    icon: renderIcon(Copy07Icon),
  },
  {
    label: 'Share',
    key: 'share_calendar',
    icon: renderIcon(Share07Icon),
  },
  {
    label: t('settings.calendar.move_to_group'),
    key: 'move_to_group',
    icon: renderIcon(ArrowRightIcon),
  },
  {
    label: t('settings.calendar.deactivate_calendar'),
    key: 'deactivate_calendar',
    icon: renderIcon(Toggle03LeftIcon),
  },
  {
    label: t('settings.calendar.troubleshoot_calendar'),
    props: { id: 'troubleshoot-calendar' },
    key: 'troubleshoot_calendar',
    icon: renderIcon(Settings01Icon),
  },
  {
    label: t('settings.calendar.delete_calendar'),
    key: 'delete_calendar',
    icon() {
      return h(renderIcon(Trash03Icon), { class: '!text-red-600' })
    },
    props: { class: 'border-t-[1px] !text-red-600' },
  },
]

const tableActionsOptionsForDisabledCalendars =
  tableActionsOptionsForActiveCalendars.filter(x => x)

tableActionsOptionsForDisabledCalendars[4] = {
  label: t('settings.calendar.activate_calendar'),
  key: 'deactivate_calendar',
  icon: renderIcon(Toggle03LeftIcon),
}

const columns = [
  // {
  //   type: 'selection',
  //   disabled: true,
  // },
  {
    title: t('settings.calendar.calendar_name'),
    key: 'name',
    render: calendar => {
      return h('div', { class: 'max-w-xs' }, [
        h(
          'div',
          { class: 'flex' },
          h(
            'span',
            {
              id: 'name',
              class: 'truncate text-sm font-medium text-gray-700  text-left',
            },
            calendar?.name
          )
        ),
        h(
          UIButton,
          {
            class: 'cursor-pointer text-gray-400',
            text: true,
            onClick: () => {
              copyCalendarId(calendar.id)
            },
          },
          [
            h(
              UITextSmNormal,
              {
                id: 'calendar-' + calendar.id,
              },
              `Id: ${calendar.id}`
            ),
            h(Copy04Icon, {
              class: 'h-3 w-3 ml-1',
            }),
          ]
        ),
      ])
    },
  },
  {
    title: t('settings.calendar.group'),
    key: 'group',
    render(calendar) {
      // return row.groupName
      return calendar.groupName
        ? h(
            'div',
            {
              class:
                'inline-flex items-center border p-2 border-gray-300 rounded px-3',
            },
            [
              h(FolderIcon, {
                class: 'h-4 w-4 mr-4',
                round: true,
                type: 'info',
                bordered: false,
              }),
              h(
                'h6',
                {
                  class: '',
                },
                calendar.groupName
              ),
            ]
          )
        : ''
    },
  },
  {
    title: t('settings.calendar.duration'),
    key: 'slotDuration',
  },
  {
    title: t('settings.calendar.calendar_type'),
    key: 'calendarType',
    render(row) {
      return row.calendarType === 'event'
        ? t('settings.calendar.event')
        : row.calendarType === 'class_booking'
        ? t('settings.calendar.class_booking')
        : row.calendarType === 'collective'
        ? 'Collective Booking'
        : row.calendarType === 'service_booking'
        ? 'Service'
        : row.calendarType === 'round_robin'
        ? t('settings.calendar.round_robin')
        : t('settings.calendar.personal')
    },
  },
  {
    title: t('settings.calendar.status'),
    key: 'isActive',
    render(calendar) {
      return h(
        'span',
        {
          class: 'inline-flex rounded-full px-2 text-xs leading-5',
          // + font-semibold  (calendar.isActive ? 'text-green-800' : 'text-red-500'),
        },
        calendar.isActive
          ? t('settings.calendar.active')
          : t('settings.calendar.draft')
      )
    },
  },
  {
    title: t('settings.calendar.date_updated'),
    key: 'dateUpdated',
    render(calendar) {
      return h('div', [
        h(
          'h5',
          {
            class: 'm-0 text-sm font-normal	',
          },
          moment(calendar.dateUpdated).tz(targetTimezone).format('MMM DD YYYY')
        ),
        h(
          'h6',
          {
            class: 'm-0 text-xs font-normal text-gray-300',
          },
          moment(calendar.dateUpdated).tz(targetTimezone).format('hh mm A')
        ),
      ])
    },
  },
  {
    title: t('settings.calendar.action_dropdown'),
    key: 'actions',
    render(calendar) {
      return h(
        UIActionDropdown,
        {
          options: tableActionDropdownOptions(calendar),
          onSelect: val => dropDownActionChange(val, calendar),
          id: 'calendar-' + calendar.id,
        },
        {}
      )
    },
  },
]

const tableActionDropdownOptions = (calendar: any) => {
  const options = calendar?.isActive
    ? tableActionsOptionsForActiveCalendars?.filter(x =>
        shouldShowLegacyLink(x.key, calendar)
      )
    : tableActionsOptionsForDisabledCalendars?.filter(x =>
        shouldShowLegacyLink(x.key, calendar)
      )

  if (!isPermissionsEnabled(CalendarScopes.EDIT_CALENDARS)) {
    return options.filter(
      x =>
        ![
          'edit',
          'duplicate',
          'deactivate_calendar',
          'move_to_group',
          'delete_calendar',
        ].includes(x.key)
    )
  }
  return options
}

const filterActionOptions = ref([
  {
    key: 'status',
    value: '',
    options: [
      {
        label: t('settings.calendar.all'),
        value: 'all',
      },
      {
        label: t('settings.calendar.draft'),
        value: 'draft',
      },
      {
        label: t('settings.calendar.active'),
        value: 'active',
      },
    ],
  },
  {
    key: 'group',
    value: '',
    options: AppState.globalTeams?.teams?.map(team => {
      return {
        label: team.name,
        value: team.id,
      }
    }),
  },
  {
    key: 'calendar_type',
    value: '',
    options: [
      {
        label: t('settings.calendar.round_robin'),
        value: CalendarType.ROUND_ROBIN,
      },
      {
        label: t('settings.calendar.event'),
        value: CalendarType.EVENT,
      },
      {
        label: t('settings.calendar.class_booking'),
        value: CalendarType.CLASS_BOOKING,
      },
      {
        label: 'Collective Booking',
        value: CalendarType.COLLECTIVE,
      },
      {
        label: 'Service',
        value: CalendarType.SERVICE,
      },
      {
        label: t('settings.calendar.personal'),
        value: CalendarType.PERSONAL,
      },
    ],
  },
])

watch(
  () => AppState.globalTeams?.teams,
  teams => {
    filterActionOptions.value[1] = {
      key: 'group',
      value: '',
      options: teams.map(team => {
        return {
          label: team.name,
          value: team.id,
        }
      }),
    }
  }
)

const calendarList = new CalendarList()

const getWidgetUrlForCalendar = (calendar: Calendar) => {
  if (
    calendar.calendarType === CalendarType.ROUND_ROBIN ||
    calendar.calendarType === CalendarType.CLASS_BOOKING ||
    calendar.calendarType === CalendarType.PERSONAL
  ) {
    const team = AppState.globalTeams?.teams?.find(
      team => team.id === calendar.providerId
    )

    if (team && calendar.slug) {
      return `${AppState?.baseUrl}/widget/appointment/service/${calendar.slug}?group=${team.slug}`
    }
    return `${AppState?.baseUrl}/widget/bookings/${calendar.widgetSlug}`
  }
  return `${AppState?.baseUrl}/widget/booking/${calendar.id}`
}

const getPermanentUrlForCalendar = (calendar: Calendar) => {
  return `${AppState?.baseUrl}/widget/booking/${calendar.id}`
}

const getNewUrlForCalendar = (calendar: Calendar) => {
  return `${AppState?.baseUrl}/widget/bookings/${calendar.widgetSlug}`
}

const getEmbedCodeForCalendar = (calendar: Calendar) => {
  const embedScript =
    calendar.widgetType === WidgetType.CLASSIC ? 'embed.js' : 'form_embed.js'

  const uniqueId = `${
    calendar.providerId ? calendar.providerId : calendar.id
  }_${new Date().getTime()}`

  const calendarId =
    calendar.widgetType === WidgetType.CLASSIC ? 'msgsndr-calendar' : uniqueId

  return `<iframe src="${getPermanentUrlForCalendar(
    calendar
  )}" style="width: 100%;border:none;overflow: hidden;" scrolling="no" id="${calendarId}"><\/iframe><br><script src="${
    AppState.embedUrl
  }/${embedScript}" type="text/javascript"><\/script>`
}

const disableCalendar = async () => {
  if (!selectedCalender.value) {
    return
  }

  isProcessing.value = true

  await CalendarService.updateStatus(
    selectedCalender.value.id,
    !selectedCalender.value.isActive
  )

  await getCalendarsInLocation(true)
  updateCalendarStore()
  closeModal()
}

const deleteCalendar = async () => {
  isProcessing.value = true
  await CalendarService.delete(selectedCalender.value.id)
  await CalendarService.deleteReserveService(selectedCalender.value.id)

  await getCalendarsInLocation(true)
  updateCalendarStore()
  closeModal()
}

const duplicateCalendar = async () => {
  isProcessing.value = true

  const fieldsToRemove = [
    'id',
    'syncToken',
    'version',
    'dateAdded',
    'providerId',
    'dateUpdated',
    'isActive',
    'deleted',
    'rrule',
    'slug',
    'groupName',
    'order',
    'unassignedLinkedCalendarId',
    'originId',
    'thanksMessage',
    'thankYouUrl',
    'onewaySyncAppointment',
    'createdBy',
    'lastUpdatedBy',
    'notificationStatus',
    'notifications',
  ]

  const fieldsToParseAsInt = [
    'slotDuration',
    'slotInterval',
    'slotBuffer',
    'preBuffer',
    'appoinmentPerSlot',
    'allowBookingAfter',
    'allowBookingFor',
  ]

  const payload = {
    ...selectedCalender.value,
  }

  fieldsToRemove.forEach(field => {
    delete payload[field]
  })

  fieldsToParseAsInt.forEach(field => {
    payload[field] = Number(payload[field])
  })

  if (selectedCalender.value.widgetSlug) {
    payload.widgetSlug = `${selectedCalender.value.widgetSlug}${generateSlug()}`
  }

  if (selectedCalender.value.teamMembers?.length === 0) {
    delete payload.teamMembers
  } else if (payload.teamMembers) {
    payload.teamMembers = payload.teamMembers.map(teamMember => ({
      ...teamMember,
      priority: Number(teamMember.priority),
    }))
  }

  payload.formSubmitRedirectURL = selectedCalender.value.formSubmitRedirectUrl
  delete payload.formSubmitRedirectUrl

  if (selectedCalender.value?.availabilities?.length > 0) {
    payload.availabilities = selectedCalender.value.availabilities.map(
      availability => {
        return {
          date: availability.date,
          hours: availability.hours,
        }
      }
    )
  }

  const {
    data: {
      calendar: { id },
    },
  } = await CalendarService.create({
    ...payload,
    name: `Copy of ${selectedCalender.value.name}`,
  })

  //get calendar resources to duplicate
  if (selectedCalender.value.calendarType === CalendarType.SERVICE) {
    const calendarId = selectedCalender.value.id
    const rooms = AppState.globalRooms
    const roomsForNewCalendar =
      rooms
        ?.map(e => e)
        ?.filter((e: any) => e.calendarIds?.includes(calendarId) && e.isActive)
        ?.map((e: any) => e?._id) || []
    const equipments = AppState.globalEquipments
    const equipmentsForNewCalendar =
      equipments
        ?.map(e => e)
        ?.filter((e: any) => e.calendarIds?.includes(calendarId) && e.isActive)
        ?.map((e: any) => e?._id) || []
    const promises: Promise<any>[] = []
    roomsForNewCalendar?.forEach(resourceId => {
      promises.push(
        CalendarResourceService.update(
          resourceId,
          { calendarIds: [id] },
          CALENDAR_RESOURCE_TYPES.ROOMS
        )
      )
    })
    equipmentsForNewCalendar.forEach(resourceId => {
      promises.push(
        CalendarResourceService.update(
          resourceId,
          { calendarIds: [id] },
          CALENDAR_RESOURCE_TYPES.EQUIPMENTS
        )
      )
    })
    await Promise.all(promises)
    await getRoomsInLocation()
    await getEquipmentsInLocation()
  }

  if (selectedCalender.value.providerId) {
    await CalendarService.moveToGroup(
      AppState.locationId,
      selectedCalender.value.providerId,
      id
    )
  }

  if (selectedCalender.value.linkedCalendars?.google?.id) {
    await CalendarService.linkGoogleCalendar(id)
  }
  toggleAdvancedView()

  AppState.selectedCalendarId = id
  AppState.isNewCalendar = true

  closeModal()
}

const notification = useNotification()

function copyCalendarId(id) {
  copyToClipboard(id)
  notification.info({
    content: 'Copied to clipboard',
    duration: 1 * 1000,
  })
}

function dropDownActionChange(action, row) {
  if (action === 'edit') {
    AppState.selectedCalendarId = row.id
    toggleAdvancedView()
  } else if (action === 'duplicate') {
    selectedCalender.value = row
    showCalendarDuplicateConfirmationDialog.value = true
  } else if (action === 'move_to_group') {
    selectedCalender.value = row
    showMoveToTeamModal.value = true
    // openAddToGroupModal(row.id)
  } else if (action === 'copy_embed_code') {
    const embedUrl = getEmbedCodeForCalendar(row)

    if (embedUrl) {
      copyToClipboard(embedUrl)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
      // toast.info('Copied to clipboard')
    }
  } else if (
    action === 'copy_scheduling_link' ||
    action === 'copy_new_slug_link' ||
    action === 'copy_permanent_link'
  ) {
    let url

    if (action === 'copy_permanent_link') {
      url = getPermanentUrlForCalendar(row)
    } else if (action === 'copy_new_slug_link') {
      url = getNewUrlForCalendar(row)
    } else {
      url = getWidgetUrlForCalendar(row)
    }

    if (url) {
      copyToClipboard(url)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
      // toast.info('Copied to clipboard')
    }
  } else if (action === 'delete_calendar') {
    selectedCalender.value = row
    showCalendarDeleteConfirmationDialog.value = true
    // deleteCalendar(calId)
  } else if (action === 'deactivate_calendar') {
    selectedCalender.value = row
    showDeactiveConfirmationModal.value = true
    // disableCalendar(row.id)
  } else if (action === 'share_calendar') {
    showSelectModal.value = true
    calendarRef.value = row
  } else if (action === 'troubleshoot_calendar') {
    calendarRef.value = row
    AppState.parentApp?.then(parent => {
      parent.emit('open-troubleshoot-calendar', {
        calendarId: row.id,
      })
    })
  }
}

const closeShareCalendarPopup = () => {
  showSelectModal.value = false
}

const closeModal = () => {
  selectedCalender.value = {}
  showDeactiveConfirmationModal.value = false
  showCalendarDeleteConfirmationDialog.value = false
  showCalendarDuplicateConfirmationDialog.value = false
  showMoveToTeamModal.value = false
  isProcessing.value = false
}

const moveSelectedCalToGroup = async selectedTeam => {
  isProcessing.value = true
  const { data } = await CalendarService.moveToGroup(
    AppState.locationId,
    selectedTeam,
    selectedCalender.value.id
  )
  await Promise.all([getCalendarsInLocation(true), getTeamsInLocation(true)])
  closeModal()
  isProcessing.value = false
  if (data.hasCalendarSlugChanged) {
    notification.info({
      content: 'The slug for this calendar was changed to avoid conflicts',
      duration: 3 * 1000,
    })
  }
}

const filterCollapseOptions = [
  {
    label: t('settings.calendar.status'),
    key: 'status',
    type: 'select',
  },
  {
    label: t('settings.calendar.group'),
    key: 'group',
    type: 'select',
  },
  {
    label: t('settings.calendar.calendar_type'),
    key: 'calendar_type',
    type: 'select',
  },
]

async function onUpdateFilter(value) {
  if (value?.filters?.length) {
    calendarList.filters.value.status =
      value?.filters?.find(x => x.key === 'status')?.value || 'all'
    calendarList.filters.value.group =
      value?.filters?.find(x => x.key === 'group')?.value || ''
    calendarList.filters.value.calendar_type =
      value?.filters?.find(x => x.key === 'calendar_type')?.value || ''
  } else {
    calendarList.filters.value.status = 'all'
    calendarList.filters.value.group = ''
    calendarList.filters.value.calendar_type = ''
  }
}

const checkedRowKeysRef = ref([])
const handleCheck = rowKeys => {
  checkedRowKeysRef.value = rowKeys
}

const selectedCalender = ref()
const showDeactiveConfirmationModal = ref(false)
const showCalendarDeleteConfirmationDialog = ref(false)
const showCalendarDuplicateConfirmationDialog = ref(false)
const isProcessing = ref(false)
const showMoveToTeamModal = ref(false)

onMounted(async () => {
  await fetchUserDetails(AppState?.user?.id, true)
})
</script>

<template>
  <div>
    <UITable
      id="calendars-list-table"
      v-model:searchText="calendarList.filters.value.searchText"
      :search-input-placeholder="t('settings.calendar.calendar_name')"
      :columns="columns"
      :data="calendarList.calendarList"
      :page-count="calendarList.nosTotalPages"
      :loading="AppState.isLoading || AppState.loadingAssets.calendars"
      :row-key="cal => cal.id"
      :filter-options="filterActionOptions"
      :filter-collapse-items="filterCollapseOptions"
      @update:checked-row-keys="handleCheck"
      @update:page="x => (calendarList.filters.value.page = x)"
      @update:filter="onUpdateFilter"
    >
      <template #empty>
        <UIEmpty
          v-if="calendarList.allCalendars.length === 0"
          id="No-calendars-found"
          :title="$t('settings.calendar.no_calendars_found_title')"
          :description="$t('settings.calendar.no_calendars_found')"
          :positive-text="
            isCreateCalendarDisabled ? '' : $t('settings.calendar.new_calendar')
          "
          :icon="CalendarCheck01Icon"
          :positive-icon="PlusIcon"
          @positive-click="openCalendarTypeModal"
        />

        <UIEmpty
          v-else
          id="No-calendars-found-2"
          :title="$t('settings.calendar.no_calendars_found_title')"
          :description="$t('settings.calendar.no_calendars_found_search')"
        />
      </template>
    </UITable>

    <GeneralConfirmation
      v-model:show="showDeactiveConfirmationModal"
      :disabled-confirm="isProcessing"
      :header-title="
        $t('settings.calendar.calendar_status_message_title', {
          status: selectedCalender?.isActive ? 'Deactivate' : 'Activate',
        })
      "
      @on-confirm="disableCalendar"
      @on-modal-close="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{
            t('settings.calendar.calendar_status_message', {
              status: selectedCalender?.isActive ? 'deactivate' : 'activate',
            })
          }}
        </p>
      </template>
    </GeneralConfirmation>

    <SelectMoveTeamModal
      :show="showMoveToTeamModal"
      :current-team="selectedCalender?.providerId"
      :disabled-confirm="isProcessing"
      @on-confirm="moveSelectedCalToGroup"
      @on-modal-close="closeModal"
    />

    <DeleteConfirmation
      v-model:show="showCalendarDeleteConfirmationDialog"
      :disabled-confirm="isProcessing"
      :header-title="$t('settings.calendar.delete_calendar_message_title')"
      @on-confirm="deleteCalendar"
      @on-modal-close="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{ t('settings.calendar.delete_calendar_message') }}
        </p>
      </template>
    </DeleteConfirmation>

    <DuplicateConfirmation
      v-model:show="showCalendarDuplicateConfirmationDialog"
      :disabled-confirm="isProcessing"
      :header-title="$t('settings.calendar.duplicate_calendar_message_title')"
      @on-confirm="duplicateCalendar"
      @on-modal-close="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{ t('settings.calendar.duplicate_calendar_message') }}
        </p>
      </template>
    </DuplicateConfirmation>
    <CalendarShareModal
      :show="showSelectModal"
      :calendar="calendarRef"
      @close-modal="closeShareCalendarPopup"
    />
  </div>
</template>
