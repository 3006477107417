import Contact from '@/models/contact'
import User from '@/models/user'

export interface Address {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  country: string
  postalCode: string
}
export interface BusinessDetails {
  id: string
  logoUrl: string
  name: string
  phoneNo: string
  email: string
  address: Address
  website: string
}

export interface ContactDetail {
  id: string
  name: string
  phoneNo: string
  companyName: string
  email: string
  address: Address
}

export interface AppointmentDetail {
  id: string
  source: string
  status: string
  appoinmentStatus: string
  createdBy: any
  contact: Contact
  bookedBy: User
  owner: User
}

export interface WebinarDetail {
  eventId: string
  webinarId: string
  appointmentIds: Array<string>
  reschedule: string
  source: string
}
export type GetAppointmentsParams = {
  filter: {
    locationId: string
    userId?: string
    contactId?: string
    calendarId?: string
    groupId?: string
    appointmentStatus?: string
  }
  sort?: {
    field: string
    direction: string
  }
  startAfter?: string
  limit?: number
  page?: number
  timezone?: string
}

export type GetCalendarEventsParams = {
  filter: {
    locationId: string
    userId?: string
    contactId?: string
    calendarId?: string
    groupId?: string
    startTime: string
    endTime: string
  }
  sort?: {
    field: string
    direction: string
  }
  includeAll?: boolean
}

export type GoogleOrganicEntity = {
  id: string
  locationId: string
  name: string
  latitude: string
  longitude: string
  address: {
    country: string
    streetAddress: string
    locality: string
    postalCode: string
    region: string
  }
  telephone: string
  url: string
  serviceGroupType: string
  serviceGroupId: string
  serviceIds: Array<string>
  isActive: boolean
}
export type ServiceMenu = {
  id: string
  name: string
  description: string
  slug: string
  isActive: boolean
  menu?: Menu[]
  dateAdded: string
  dateUpdated: string
  formSubmitType: string
  formSubmitRedirectUrl: string
  formSubmitThanksMessage: string
  consentLabel: string
  enableConsentCheck: boolean
}
export type Menu = {
  groupId: string
  name: string
  sortOrder: number
  calendarIds: Array<{ id: string }>
  type?: string
}

export enum CalendarScopes {
  EDIT_CALENDARS = 'calendars.write',
  READ_CALENDARS = 'calendars.readonly',
  EDIT_CALENDAR_GROUPS = 'calendars/groups.write',
  READ_CALENDAR_GROUPS = 'calendars/groups.readonly',
  EDIT_CALENDAR_EVENTS = 'calendars/events.write',
  READ_CALENDAR_EVENTS = 'calendars/events.readonly',
}