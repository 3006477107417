<!-- eslint-disable no-console -->
<script setup lang="ts">
import emailCode from '@/assets/email-code.svg'
import emailDesign from '@/assets/email-design.svg'
import emailText from '@/assets/email-text.svg'
import { InfoCircleIcon, XCloseIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIModal } from '@gohighlevel/ghl-ui'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  EmailBuilderService,
  ICreateEmailTemplateOptions,
} from '../../../class/services/EmailService'
import AppState from '../../../states/app'
const { t } = useI18n()

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  onCancel: {
    type: Function,
    required: true,
  },
  onSuccess: {
    type: Function,
    required: true,
  },
})

const state = reactive({
  isLoading: false,
  selectedType: '',
})

const templateOptions = [
  {
    id: 'design',
    title: t('emailTemplate.design_editor'),
    description: t('emailTemplate.design_editor_desc'),
    icon: emailDesign,
    type: 'html',
    testId: 'cal-email__empty-design-btn',
    isPlainText: false,
  },
  {
    id: 'code',
    title: t('emailTemplate.code_editor'),
    description: t('emailTemplate.code_editor_desc'),
    icon: emailCode,
    type: 'blank',
    testId: 'cal-email__empty-code-btn',
    isPlainText: false,
  },
  {
    id: 'text',
    title: t('emailTemplate.text_editor'),
    description: t('emailTemplate.text_editor_desc'),
    icon: emailText,
    type: 'html',
    testId: 'cal-email__empty-code-btn',
    isPlainText: true,
  },
]

const createNewEmailTemplate = async (type: string, isPlainText = false) => {
  state.selectedType = type
  state.isLoading = true
  try {
    const createEmailParam = {
      locationId: AppState.locationId,
      type: type,
      updatedBy: AppState.user?.id,
      title: t('emailTemplate.new_template'),
      isPlainText,
    } as ICreateEmailTemplateOptions
    const { data } = await EmailBuilderService.createEmailTemplate(
      createEmailParam
    )
    props.onSuccess()
    AppState.parentApp?.then((parent: any) => {
      parent.emit('open-email-builder', data.redirect)
    })
  } catch (error) {
    console.error(error)
  } finally {
    state.isLoading = false
  }
}
</script>

<template>
  <UIModal :show="show" :width="960">
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex flex-col items-start gap-1">
          <div class="text-[18px] font-medium leading-6">
            {{ $t('emailTemplate.select_editing_experience') }}
          </div>
          <div class="text-sm font-normal text-gray-500">
            {{ $t('emailTemplate.select_editing_experience_desc') }}
          </div>
        </div>
        <XCloseIcon
          class="h-5 w-5 cursor-pointer text-gray-500"
          role="button"
          @click="onCancel()"
          @keydown="onCancel()"
        />
      </div>
    </template>

    <template #default>
      <div class="flex flex-col gap-1">
        <div class="mb-5 mt-6 flex justify-between gap-5">
          <div
            v-for="option in templateOptions"
            :id="`cal-email-template-selector__btn-${option.id}`"
            :key="option.id"
            class="group group relative flex flex-col items-center rounded-xl border border-gray-300 p-5 text-center hover:border-primary-600 hover:bg-primary-50"
          >
            <div class="text-2xl font-medium leading-6">
              {{ $t(option.title) }}
            </div>
            <div
              class="mt-[10px] w-60 truncate font-normal leading-6 text-gray-600"
              :title="$t(option.description)"
            >
              {{ $t(option.description) }}
            </div>
            <img
              :src="option.icon"
              class="my-[50px] h-[135px] w-[200px]"
              alt="email template icon"
            />
            <UIButton
              :id="option.testId"
              class="w-full bg-primary-25 text-primary-300 group-hover:bg-primary-600 group-hover:text-white"
              type="transparent"
              :loading="state.isLoading && state.selectedType === option.type"
              :disabled="state.isLoading"
              @click="createNewEmailTemplate(option.type, option.isPlainText)"
            >
              {{ $t('emailTemplate.select') }}
            </UIButton>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <InfoCircleIcon class="h-4 w-4 text-gray-500" />
          <div class="text-sm font-normal text-gray-400">
            {{ $t('emailTemplate.email_block') }}
          </div>
        </div>
      </div>
    </template>
  </UIModal>
</template>
