<script setup lang="ts">
import { GroupList } from '@/class/pages/GroupList'
import { CalendarGroupService } from '@/class/services/CalendarGroupService'
import DeleteConfirmation from '@/components/common/DeleteConfirmation.vue'
import GeneralConfirmation from '@/components/common/GeneralConfirmation.vue'
import CreateGroupModal from '@/components/Settings/CreateGroupModal.vue'
import RearrangeCalendarsModal from '@/components/Settings/RearrangeCalendarsModal.vue'
import Team from '@/models/team'
import AppState, {
  getCalendarsInLocation,
  getTargetTimezone,
  getTeamsInLocation,
  isCreateGroupDisabled,
  isPermissionsEnabled,
  updateCalendarStore,
  updateTeamsStore,
} from '@/states/app'
import { copyToClipboard } from '@/utils/generalFunctions'
import {
  AlertHexagonIcon,
  CalendarCheck01Icon,
  Code01Icon,
  Link01Icon,
  MoveIcon,
  Pencil02Icon,
  PlusIcon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIActionDropdown,
  UICheckbox,
  UIDivider,
  UIEmpty,
  UITable,
  renderIcon,
  useNotification,
} from '@gohighlevel/ghl-ui'
import moment from 'moment-timezone'
import { h, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarScopes } from '../../types'

const { t } = useI18n()

const tableActionsOptionsForGroups = [
  {
    label: t('settings.calendar.edit'),
    key: 'edit',
    icon: renderIcon(Pencil02Icon),
  },
  {
    label: t('settings.calendar.copy_embed_code'),
    key: 'copy_embed_code',
    icon: renderIcon(Code01Icon),
  },
  {
    label: t('settings.calendar.copy_permanent_link'),
    key: 'copy_permanent_link',
    icon: renderIcon(Link01Icon),
  },
  {
    label: t('settings.calendar.copy_new_slug_link'),
    key: 'copy_scheduling_link_new',
    icon: renderIcon(Link01Icon),
  },
  {
    label: t('settings.calendar.copy_scheduling_link'),
    key: 'copy_scheduling_link',
    icon: renderIcon(Link01Icon),
  },
  {
    label: t('settings.calendar.rearrange_calendars'),
    key: 'rearrange_calendars',
    icon: renderIcon(MoveIcon),
  },
  {
    label: t('settings.calendar.deactivate_all_calendars_in_group'),
    key: 'toggle_group',
    icon: renderIcon(AlertHexagonIcon),
  },
  {
    label: t('settings.calendar.delete_group'),
    key: 'delete_group',
    icon: renderIcon(Trash01Icon),
  },
]
const tableActionsOptionsForDisabledGroups = tableActionsOptionsForGroups.map(
  x => x
)
tableActionsOptionsForDisabledGroups[6] = {
  label: t('settings.calendar.activate_all_calendars_in_group'),
  key: 'toggle_group',
  icon: renderIcon(AlertHexagonIcon),
}

const targetTimezone = getTargetTimezone()

const columns = [
  {
    title: t('settings.calendar.group_name'),
    key: 'name',
  },
  {
    title: t('settings.calendar.calendars'),
    key: 'nosOfCalendars',
  },
  {
    title: t('settings.calendar.date_updated'),
    key: 'dateUpdated',
    render(team) {
      return h('div', [
        h(
          'h5',
          {
            class: 'm-0 text-sm font-normal	',
          },
          moment(team.dateUpdated).tz(targetTimezone).format('MMM DD YYYY')
        ),
        h(
          'h6',
          {
            class: 'm-0 text-xs font-normal text-gray-300',
          },
          moment(team.dateUpdated).tz(targetTimezone).format('hh mm A')
        ),
      ])
    },
  },
  {
    title: t('settings.calendar.status'),
    key: 'isActive',
    render(group) {
      return h(
        'span',
        {
          class: 'inline-flex rounded-full px-2 text-xs leading-5',
          // + font-semibold  (group.isActive ? 'text-green-800' : 'text-red-500'),
        },
        group.isActive
          ? t('settings.calendar.active')
          : t('settings.calendar.draft')
      )
    },
  },
  {
    title: t('settings.calendar.action_dropdown'),
    key: 'actions',
    render(row) {
      return h(
        UIActionDropdown,
        {
          options: tableActionDropdownOptions(row),
          onSelect: val => dropDownActionChange(val, row),
          id: 'group-' + row.id,
        },
        {}
      )
    },
  },
]

const tableActionDropdownOptions = (row: any) => {
  const options = row.isActive
    ? tableActionsOptionsForGroups.filter(
        x => x.key !== 'rearrange_calendars' || row.nosOfCalendars > 0
      )
    : tableActionsOptionsForDisabledGroups.filter(
        x => x.key !== 'rearrange_calendars' || row.nosOfCalendars > 0
      )

  if (!isPermissionsEnabled(CalendarScopes.EDIT_CALENDAR_GROUPS)) {
    return options.filter(
      x =>
        ![
          'edit',
          'delete_group',
          'rearrange_calendars',
          'toggle_group',
        ].includes(x.key)
    )
  }

  return options
}

const groupList = new GroupList()
const notification = useNotification()

const dropDownActionChange = (action, team) => {
  if (action === 'edit') {
    // openTeamModal(team.id)
    openGroupModal(team)
  } else if (action === 'copy_embed_code') {
    const embedUrl = getEmbedCodeForTeam(team.id)

    if (embedUrl) {
      copyToClipboard(embedUrl)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
      // toast.info('Copied to clipboard')
    }
  } else if (
    action === 'copy_scheduling_link' ||
    action === 'copy_scheduling_link_new' ||
    action === 'copy_permanent_link'
  ) {
    let url

    if (action === 'copy_permanent_link') {
      url = getPermanentUrlForTeam(team.id)
    } else if (action === 'copy_scheduling_link_new') {
      url = getWidgetUrlForTeam(team.id, true)
    } else {
      url = getWidgetUrlForTeam(team.id)
    }

    if (url) {
      copyToClipboard(url)
      notification.info({
        content: 'Copied to clipboard',
        duration: 1 * 1000,
      })
      // toast.info('Copied to clipboard')
    }
  } else if (action === 'delete_group') {
    selectedGroup.value = team
    showDeleteConfirmationModal.value = true
  } else if (action === 'rearrange_calendars') {
    selectedGroup.value = team
    showRearrangeModal.value = true
  } else if (action === 'toggle_group') {
    selectedGroup.value = team
    showDeactivateConfirmationModal.value = true
  }
}

const disableGroup = async () => {
  if (!selectedGroup.value) {
    return
  }

  isProcessing.value = true

  const { data } = await CalendarGroupService.updateStatus(
    selectedGroup.value.id,
    !selectedGroup.value.isActive
  )
  await Promise.all([getTeamsInLocation(true), getCalendarsInLocation(true)])
  updateCalendarStore()
  updateTeamsStore()
  closeModal()
}

const getWidgetUrlForTeam = (teamId: string, isNew = false) => {
  const team = groupList.allTeams.find(team => team.id === teamId)
  if (team) {
    if (isNew) {
      return `${AppState.baseUrl}/widget/groups/${team.slug}`
    }
    return `${AppState.baseUrl}/widget/appointment/${team.slug}`
  }
}

const getPermanentUrlForTeam = (teamId: string) => {
  return `${AppState.baseUrl}/widget/group/${teamId}`
}

const getEmbedCodeForTeam = (teamId: string) => {
  const team = groupList.allTeams.find(team => team.id === teamId)

  if (team) {
    const uniqueId = `${teamId}_${new Date().getTime()}`

    return `<iframe src="${getPermanentUrlForTeam(
      teamId
    )}" style="width: 100%;border:none;overflow: hidden;" scrolling="no" id="${uniqueId}"><\/iframe><br><script src="${
      AppState.embedUrl
    }/form_embed.js" type="text/javascript"><\/script>`
  }
}

const closeModal = () => {
  selectedGroup.value = {}
  showDeleteConfirmationModal.value = false
  showDeactivateConfirmationModal.value = false
  isProcessing.value = false
  deleteCalendarsCheckBoxValue.value = false
}

const deleteGroup = async () => {
  if (!selectedGroup.value) {
    return
  }

  isProcessing.value = true
  await CalendarGroupService.delete(
    selectedGroup.value.id,
    deleteCalendarsCheckBoxValue.value
  )
  const fetchCalendars = deleteCalendarsCheckBoxValue.value
  closeModal()
  await Promise.all([getTeamsInLocation(true), getCalendarsInLocation(true)])
  if (fetchCalendars) {
    updateCalendarStore()
  }
  updateTeamsStore()
}

const selectedGroup = ref()
const showDeleteConfirmationModal = ref(false)
const showDeactivateConfirmationModal = ref(false)
const isProcessing = ref(false)
const showNewGroupModal = ref(false)
const showRearrangeModal = ref(false)
const groupEditPayload = ref()
const deleteCalendarsCheckBoxValue = ref(false)

const closeGroupModal = () => {
  showNewGroupModal.value = false
}

const editGroupSuccess = async () => {
  await getTeamsInLocation(true)
  closeGroupModal()
  updateTeamsStore()
}

const openGroupModal = (team: Team) => {
  showNewGroupModal.value = true
  groupEditPayload.value = team
}
</script>

<template>
  <div>
    <CreateGroupModal
      :show="showNewGroupModal"
      :edit-payload="groupEditPayload"
      @created-record="editGroupSuccess"
      @close-modal="closeGroupModal"
    />
    <RearrangeCalendarsModal
      :team-id="selectedGroup?.id"
      :show="showRearrangeModal"
      @close-modal="() => (showRearrangeModal = false)"
    />
    <UITable
      id="group-list-table"
      v-model:searchText="groupList.filters.value.searchText"
      :search-input-placeholder="$t('settings.calendar.group_name')"
      :columns="columns"
      :data="groupList.teamsList"
      :page-count="groupList.nosTotalPages"
      :loading="AppState.isLoading || AppState.loadingAssets.groups"
      @update:page="x => (groupList.filters.value.page = x)"
    >
      <template #empty>
        <UIEmpty
          v-if="groupList.allTeams.length === 0"
          :title="$t('settings.calendar.no_groups_in_location_title')"
          :description="$t('settings.calendar.no_groups_in_location')"
          :positive-text="
            isCreateGroupDisabled
              ? ''
              : $t('settings.calendar.add_new_calendar_group')
          "
          :icon="CalendarCheck01Icon"
          :positive-icon="PlusIcon"
          @positive-click="openGroupModal"
        />

        <UIEmpty
          v-else
          :title="$t('settings.calendar.no_groups_in_location_title')"
          :description="$t('settings.calendar.no_groups_found')"
        />
      </template>
    </UITable>

    <DeleteConfirmation
      v-model:show="showDeleteConfirmationModal"
      :disabled-confirm="isProcessing"
      :header-title="$t('settings.calendar.delete_this_group')"
      @on-confirm="deleteGroup"
      @on-modal-close="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{ $t('settings.calendar.delete_group_confirmation') }}
        </p>
        <div v-if="selectedGroup?.nosOfCalendars > 0">
          <UIDivider />
          <UICheckbox
            id="group-delete-model"
            v-model:checked="deleteCalendarsCheckBoxValue"
            class="mb-2"
          >
            <div class="ml-[2px] mt-[-1px]">
              <div class="text-black-600 text-base">
                {{
                  $t(
                    'settings.calendar.delete_group_confirmation_checkbox_title'
                  )
                }}
              </div>
              <p class="mt-[5px] text-sm leading-6 text-gray-500">
                {{
                  $t(
                    'settings.calendar.delete_group_confirmation_checkbox_description'
                  )
                }}
              </p>
            </div>
          </UICheckbox>
        </div>
      </template>
    </DeleteConfirmation>

    <GeneralConfirmation
      v-model:show="showDeactivateConfirmationModal"
      :disabled-confirm="isProcessing"
      :header-title="
        $t('settings.calendar.group_status_message_title', {
          status: selectedGroup?.isActive ? 'Deactivate' : 'Activate',
        })
      "
      @on-confirm="disableGroup"
      @on-modal-close="closeModal"
    >
      <template #message>
        <p class="text-sm leading-5 text-gray-500">
          {{
            t('settings.calendar.group_status_message', {
              status: selectedGroup?.isActive ? 'deactivate' : 'activate',
            })
          }}
        </p>
      </template>
    </GeneralConfirmation>
  </div>
</template>
