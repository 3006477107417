<script setup lang="ts">
import CalendarLinkModal from '@/components/Settings/CalendarLinkModal.vue'
import CalendarListTable from '@/components/Settings/CalendarListTable.vue'
import CreateCalendarModalVue from '@/components/Settings/CreateCalendarModal.vue'
import CreateGroupModal from '@/components/Settings/CreateGroupModal.vue'
import GroupListTable from '@/components/Settings/GroupListTable.vue'
import SelectCalendarTypeModalVue_V2 from '@/components/Settings/SelectCalendarTypeModalV2.vue'
import SelectTeamModalVue from '@/components/Settings/SelectTeamModal.vue'
import Calendar, { CalendarType } from '@/models/calendar'
import {
  getCalendarsInLocation,
  getEquipmentsInLocation,
  getRoomsInLocation,
  getTeamsInLocation,
  getUsersInLocation,
  isEquipmentEnabled,
  isRoomEnabled,
  isServiceCalendarEnabled,
  resetCalendarEditValidationData,
  setEditingCalendar,
  setIsLoading,
  updateCalendarStore,
  updateTeamsStore,
} from '@/states/app'
import {
  CalendarIcon,
  PlusIcon,
  UserPlus01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIHeader,
  UITabPane,
  UITabs,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import CreateEquipmentModal from '../components/Settings/CreateEquipmentModal.vue'
import CreateRoomModal from '../components/Settings/CreateRoomModal.vue'
import EquipmentsListTable from '../components/Settings/EquipmentsListTable.vue'
import RoomsListTable from '../components/Settings/RoomsListTable.vue'
import ServicesListTable from '../components/Settings/ServicesListTable.vue'
import AppState, {
  isCreateCalendarDisabled,
  isCreateGroupDisabled,
} from '../states/app'
import { toggleAdvancedServiceMenuView } from '../utils/commonModalFunctions'

const showNewGroupModal = ref(false)
const showNewEquipmentModal = ref(false)
const showNewRoomModal = ref(false)
const showCalendarTypeModal = ref(false)
const showCreateCalendarModal = ref(false)
const showCalendarLinkModal = ref(false)
const showSelectTeamModal = ref(false)
const selectedCalendarType = ref(CalendarType.EVENT)
const route = useRoute()

const closeGroupModal = () => {
  showNewGroupModal.value = false
}

const newGroupCreated = async () => {
  closeGroupModal()
  await getTeamsInLocation(true)
  updateTeamsStore()
}
const openSelectTeamModal = (calendarType: CalendarType) => {
  selectedCalendarType.value = calendarType
  showCreateCalendarModal.value = true

  showCalendarTypeModal.value = false
}

const openClassBookingCalendarModal = () => {
  selectedCalendarType.value = CalendarType.CLASS_BOOKING
  showCreateCalendarModal.value = true

  showCalendarTypeModal.value = false
}

const openCollectiveBookingCalendarModal = () => {
  selectedCalendarType.value = CalendarType.COLLECTIVE
  showCreateCalendarModal.value = true

  showCalendarTypeModal.value = false
}

const openUnassignedCalendar = () => {
  selectedCalendarType.value = CalendarType.EVENT
  showCreateCalendarModal.value = true

  showCalendarTypeModal.value = false
}

const openTeamCalendar = selectedGroupId => {
  showCreateCalendarModal.value = true
}

const openCalendarTypeModal = () => {
  resetCalendarEditValidationData()
  showCalendarTypeModal.value = true
}

const newCalendarCreated = async (calendar: Calendar) => {
  showCreateCalendarModal.value = false
  showCalendarLinkModal.value = true
  setEditingCalendar(calendar)
  setIsLoading(true)
  await getCalendarsInLocation(true)
  updateCalendarStore()
  setIsLoading(false)
}

watch(
  () => AppState.showAdvancedCalendarSettings,
  () => {
    showCalendarLinkModal.value = false
  }
)

const openServiceMenu = () => {
  toggleAdvancedServiceMenuView()
}

onMounted(async () => {
  setIsLoading(true)
  await getTeamsInLocation()
  await getCalendarsInLocation()
  await getUsersInLocation()
  setIsLoading(false)
})

const openCreateResourceModal = () => {
  if (AppState.selectedTab === 'equipments') {
    showNewEquipmentModal.value = true
  } else if (AppState.selectedTab === 'rooms') {
    showNewRoomModal.value = true
  }
}

const closeEquipmentModal = () => {
  showNewEquipmentModal.value = false
}

const newEquipmentCreated = () => {
  getEquipmentsInLocation()
  closeEquipmentModal()
}

const closeRoomModal = () => {
  showNewRoomModal.value = false
}

const newRoomCreated = () => {
  getRoomsInLocation()
  closeRoomModal()
}

const showServiceMenuTab = computed(() => {
  return route.query?.tab === 'services' || isServiceCalendarEnabled()
})
</script>

<template>
  <div class="p-8">
    <SelectCalendarTypeModalVue_V2
      :show="showCalendarTypeModal"
      :open-unassigned-calendar-modal="openUnassignedCalendar"
      :open-team-calendar-modal="openSelectTeamModal"
      :open-class-booking-calendar-modal="openClassBookingCalendarModal"
      :open-collective-booking-calendar-modal="
        openCollectiveBookingCalendarModal
      "
      @on-modal-close="() => (showCalendarTypeModal = false)"
    />

    <CalendarLinkModal
      v-if="showCalendarLinkModal"
      :show="showCalendarLinkModal"
      @on-modal-close="() => (showCalendarLinkModal = false)"
    />
    <CreateCalendarModalVue
      v-if="showCreateCalendarModal"
      :show="showCreateCalendarModal"
      :selected-calendar-type="selectedCalendarType"
      @on-modal-close="() => (showCreateCalendarModal = false)"
      @on-confirm="newCalendarCreated"
    />
    <SelectTeamModalVue
      :show="showSelectTeamModal"
      @on-modal-close="() => (showSelectTeamModal = false)"
      @on-confirm="openTeamCalendar"
    />

    <UIHeader
      v-if="AppState.selectedTab === 'services'"
      id="service-menu-settings-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="
        $t('settings.calendar.manage_your_calendars_groups_and_service_menus')
      "
      :primary-text="$t('settings.calendar.new_service_menu')"
      :primary-action-icon="PlusIcon"
      @primary-click="openServiceMenu"
    />

    <!-- Custom header to show tooltips for disabled states -->
    <div
      v-if="!['services', 'rooms', 'equipments'].includes(AppState.selectedTab)"
      class="mb-8 flex w-full flex-row items-center justify-between"
    >
      <div class="flex flex-col">
        <div class="text-[30px] font-medium text-gray-900">
          {{ $t('settings.calendar.calendar_settings') }}
        </div>
        <div class="text-[16px] text-gray-500">
          {{ $t('settings.calendar.manage_your_calendars_and_groups') }}
        </div>
      </div>
      <div class="flex flex-row items-center gap-2">
        <UITooltip :trigger="isCreateGroupDisabled ? 'hover' : 'manual'">
          <template #trigger>
            <UIButton
              id="calendar-settings-headers-btn-secondary"
              type="default"
              :disabled="isCreateGroupDisabled"
              @click="showNewGroupModal = true"
              @keydown="showNewGroupModal = true"
            >
              <div class="flex flex-row items-center gap-2">
                <UserPlus01Icon class="h-5 w-5 text-gray-900" />
                {{ $t('settings.calendar.create_group') }}
              </div>
            </UIButton>
          </template>
          <div class="max-w-[300px]">
            {{ $t('settings.calendar.create_group_access_disabled') }}
          </div>
        </UITooltip>
        <UITooltip :trigger="isCreateCalendarDisabled ? 'hover' : 'manual'">
          <template #trigger>
            <UIButton
              id="calendar-settings-headers-btn-primary"
              type="primary"
              :disabled="isCreateCalendarDisabled"
              @click="openCalendarTypeModal"
              @keydown="openCalendarTypeModal"
            >
              <div class="flex flex-row items-center gap-2">
                <CalendarIcon class="h-5 w-5 text-white" />
                {{ $t('settings.calendar.create_calendar') }}
              </div>
            </UIButton>
          </template>
          <div class="max-w-[300px]">
            {{ $t('settings.calendar.create_calendar_access_disabled') }}
          </div>
        </UITooltip>
      </div>
    </div>

    <UIHeader
      v-if="AppState.selectedTab === 'rooms'"
      id="room-menu-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="$t('settings.calendar.manage_your_rooms')"
      :primary-text="$t(`settings.calendar.create_${AppState.selectedTab}`)"
      :primary-action-icon="PlusIcon"
      @primary-click="openCreateResourceModal"
    />
    <UIHeader
      v-if="AppState.selectedTab === 'equipments'"
      id="equipments-menu-headers"
      :title="$t('settings.calendar.calendar_settings')"
      :description="$t('settings.calendar.manage_your_equipments')"
      :primary-text="$t(`settings.calendar.create_${AppState.selectedTab}`)"
      :primary-action-icon="PlusIcon"
      @primary-click="openCreateResourceModal"
    />

    <UITabs v-model:value="AppState.selectedTab" type="line">
      <UITabPane
        name="calendars"
        :tab="$t('settings.calendar.calendars')"
        class="mt-6"
      >
        <CalendarListTable :open-calendar-type-modal="openCalendarTypeModal" />
      </UITabPane>

      <UITabPane
        name="groups"
        class="mt-6"
        :tab="$t('settings.calendar.groups')"
      >
        <GroupListTable />
      </UITabPane>

      <UITabPane
        v-if="showServiceMenuTab"
        name="services"
        :tab="$t('settings.calendar.service_menus')"
        class="mt-6"
      >
        <ServicesListTable />
      </UITabPane>

      <UITabPane
        v-if="isRoomEnabled()"
        name="rooms"
        :tab="$t('settings.calendar.rooms')"
        class="mt-6"
      >
        <RoomsListTable />
      </UITabPane>

      <UITabPane
        v-if="isEquipmentEnabled()"
        name="equipments"
        :tab="$t('settings.calendar.equipments')"
        class="mt-6"
      >
        <EquipmentsListTable />
      </UITabPane>
    </UITabs>

    <CreateGroupModal
      :show="showNewGroupModal"
      @created-record="newGroupCreated"
      @close-modal="closeGroupModal"
    />
    <CreateEquipmentModal
      :show="showNewEquipmentModal"
      @created-record="newEquipmentCreated"
      @close-modal="closeEquipmentModal"
    />
    <CreateRoomModal
      :show="showNewRoomModal"
      @created-record="newRoomCreated"
      @close-modal="closeRoomModal"
    />
  </div>
</template>
